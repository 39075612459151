.c-event-location__name {
  display: block;

  @include mq($from: sm) {
    display: inline;

    &::after {
      content: '\2022';
      display: inline;
      padding: 0 12px;
    }
  }
}
