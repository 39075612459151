/* ==========================================================================
   #GLOBAL
   ========================================================================== */

/**
 * The global settings file contains any project-wide variables; things that
 * need to be made available to the entire codebase.
 */


/* Global widths
   ========================================================================== */
/**
 * Convert site width into ems
 * http://bradfrost.com/blog/post/7-habits-of-highly-effective-media-queries/#relative
**/
$global-site-width: 1366px / 16px * 1em;


/**
 * Content width for better line length readability
 *
 */
$global-content-width: 45rem;
$global-wide-measure: 45rem;
$global-base-measure: 30rem;
$global-narrow-measure: 20rem;


/* Global UI treatments
   ========================================================================== */
$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;


/* Grey color palette
   ========================================================================== */
$grey-900:                      #1a202c;
$grey-800:                      #2d3748;
$grey-darker:                   $grey-800;
$grey-700:                      #4a5568;
$grey-600:                      #718096;
$grey-dark:                     $grey-600;
$grey-500:                      #a0aec0;
$grey:                          $grey-500;
$grey-400:                      #cbd5e0;
$grey-light:                    $grey-400;
$grey-300:                      #e2e8f0;
$grey-200:                      #edf2f7;
$grey-lighter:                  $grey-200;
$grey-100:                      #f7fafc;
$grey-lightest:                 $grey-100;
$gray-100:                      #f7fafc;
$gray-200:                      #edf2f7;
$gray-300:                      #e2e8f0;
$gray-400:                      #cbd5e0;
$gray-500:                      #a0aec0;
$gray-600:                      #718096;
$gray-700:                      #4a5568;
$gray-800:                      #2d3748;
$gray-900:                      #1a202c;


/* UI color palette
   ========================================================================== */
$deep-indigo:                   #252534;
$indigo:                        #3c366b;
$indigo-900:                    #3c366b;
$indigo-500:                    #667eea;
$indigo-300:                    #A3BFFA;
$indigo-100:                    #ebf4ff;

$yellow:                        #FDF41B;
$light-yellow:                  #FDFFBA;
$yellow-100:                    #fffff0;
$yellow-200:                    #fefcbf;
$yellow-300:                    #FCD34D;

$green:                         #21B58D;
$bright-green:                  #00FFBB;
$green-50:                      #EDFDF5;
$green-100:                     #D3FAE6;
$green-200:                     #ABF3D1;
$green-400:                     #44D39A;
$green-500:                     #2AB982;
$green-600:                     #1E966A;
$green-700:                     #167858;
$green-900:                     #0E4E3B;

$blue-100:                      #ebf8ff;
$blue-200:                      #bee3f8;
$blue-300:                      #90cdf4;
$blue-400:                      #63b3ed;
$blue-500:                      #4299e1;
$blue-600:                      #3182ce;
$blue-700:                      #2b6cb0;
$blue-800:                      #2c5282;
$blue-900:                      #2a4365;
$blue:                          $blue-500;

$blue-green:                    #0092C0;

$red-100:                       #fff5f5;
$red-500:                       #f56565;
$red:                           $red-500;
$red-600:                       #e53e3e;
$red-700:                       #c53030;

$orange-100:                    #fffaf0;
$orange-400:                    #f6ad55;
$orange-500:                    #ed8936;
$orange:                        $orange-500;
$orange-600:                    #dd6b20;
$orange-700:                    #c05621;
$orange-900:                    #7b341e;

$purple:                        #3D3D99;

$pink-900:                      #702459;



/* Brand color palette
   ========================================================================== */
$primary-brand-color:           $deep-indigo;
$global-text-color:             #333;
$pro-color:                     $bright-green;


/* Background colors
   ========================================================================== */
$global-body-background:        $grey-lightest !default;


/* Font families
   ========================================================================== */
$sans-font-family:              "benton-sans", sans-serif;
$display-font-family:           "rift", sans-serif;
$serif-font-family:             "ff-meta-serif-web-pro",serif;
$heading-font-family:           $sans-font-family;
$global-regular-weight:         400;
$global-bold-weight:            700;
$global-header-weight:          700;
$global-header-color:           #000;

/* Shadows
  =========================================================================== */
$global-feather-shadow:         0 2px 20px rgba(0,0,0,.05);
$global-soft-shadow:            0 1px 2px rgba(0,0,0,.15);

/* Forms
   ===========================================================================*/
$global-input-height:           2.5rem;


/* Content layout
   ========================================================================== */
$content-meta-width:            200px;
$content-grid-gap-md:           $global-spacing-unit;
$content-grid-gap-lg:           $global-spacing-unit-lg
