.c-entry {
  display: flex;
  flex-flow: column;
}

.c-entry__header,
.c-entry__body {
  padding: 3%;
}

.c-entry__header {
  border-bottom: 1px solid $grey-lighter;
}

.c-entry__media {
  order: -1;

  .c-EntryFigure-caption {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.c-entry__main {
  flex: 1;

  img {
    border: 1px solid $grey-lightest;
    box-shadow: 0 0 6px rgba(0,0,0,.05);
  }
}

.c-entry__meta {
  order: -1;
}

@include mq($from: md) {

  .c-entry__media {
    order: initial;
  }

  .c-entry__body {
    display: flex;
  }

  .c-entry__meta {
    margin-right: 1.44rem;
    width: 200px;
  }
}

@include mq($from: lg) {

}

.c-EntryTitle {
  font-family: $heading-font-family;
}

.c-entry-text {
  line-height: 1.625;

  h2,h3,h4,h5,h6 {
    font-family: $sans-font-family;
    font-weight: 600;
    margin-bottom: .5rem;
    margin-top: 1em;
  }

  h2 { font-size: 2.25rem; }
  h3 { font-size: 1.875rem; }
  h4 { font-size: 1.5rem; }
  h5 { font-size: 1.25rem; }
  h6 { font-size: 1.125rem; }

  > p,
  > ul,
  > ol,
  > table,
  > figure {
    margin-bottom: $global-spacing-unit;
  }

  > ol,
  > ul {
    padding-left: 2rem;

    li + li {
      margin-top: .5rem;
    }
  }

  > ol {
    list-style-type: decimal-leading-zero;
  }

  > ul {
    list-style-type: square;

    li {
      margin-bottom: .402em;
    }
  }

  > table {
    font-family: $sans-font-family;
    font-size: .833em;

    thead th {
      background: #fff;
      position: sticky;
      text-transform: uppercase;
      top: 0;
    }
  }
}

.c-entry-block-quote {
  background: $grey-lightest;
  margin-bottom: $global-spacing-unit;
  padding: 5%;

  blockquote {
    font-style: italic;
    font-size: 1.25em;
    line-height: 1.625;
  }
}

.c-EntryFigure {
  max-width: $global-content-width;
}

.c-EntryFigure-caption {
  color: $grey-dark;
  font-size: .875em;
  padding-top: .5em;
}

.c-EntryMeta {
  display: flex;
  font-size: .75em;
  padding: 9px 0;
  text-align: center;

  a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }

  img {
    margin-bottom: 1em;
  }
}

.c-EntryMeta-author {
  align-self: center;
  display: block;
  margin-right: 1em;
  text-transform: uppercase;
}

.c-EntryMeta-date {
  align-self: center;
}
