/* Grid template columns
   ========================================================================== */
.u-grid-cols-2 { grid-template-columns: repeat(2, minmax(0, 1fr)); }
.u-grid-cols-3 { grid-template-columns: repeat(3, minmax(0, 1fr)); }
.u-grid-cols-4 { grid-template-columns: repeat(4, minmax(0, 1fr)); }
.u-grid-cols-5 { grid-template-columns: repeat(5, minmax(0, 1fr)); }
.u-grid-cols-6 { grid-template-columns: repeat(6, minmax(0, 1fr)); }

/* Grid column start / end
   ========================================================================== */
.u-col-span-2 { grid-column: span 2 / span 2; }
.u-col-span-3 { grid-column: span 3 / span 3; }
.u-col-span-4 { grid-column: span 4 / span 4; }

/* Gap
   ========================================================================== */
.u-gap-1 { gap: .25rem; }
.u-gap-2 { gap: .5rem; }
.u-gap-3 { gap: .75rem; }
.u-gap-4 { gap: 1rem; }
.u-gap-5 { gap: 1.25rem; }
.u-gap-6 { gap: 1.5rem; }
.u-gap-8 { gap: 2rem; }

@include mq($from: sm) {
  .sm\:u-grid-cols-2 { grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .sm\:u-grid-cols-4 { grid-template-columns: repeat(4, minmax(0, 1fr)); }

  .sm\:u-col-span-1 { grid-column: span 1 / span 1; }
  .sm\:u-col-span-2 { grid-column: span 2 / span 2; }
  .sm\:u-col-span-3 { grid-column: span 3 / span 3; }
  .sm\:u-col-span-4 { grid-column: span 4 / span 4; }
}

@include mq($from: md) {
  .md\:u-grid-cols-2 { grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .md\:u-grid-cols-4 { grid-template-columns: repeat(4, minmax(0, 1fr)); }
  .md\:u-grid-cols-5 { grid-template-columns: repeat(5, minmax(0, 1fr)); }
  .md\:u-grid-cols-6 { grid-template-columns: repeat(6, minmax(0, 1fr)); }

  .md\:u-col-span-2 { grid-column: span 2 / span 2; }
  .md\:u-col-span-3 { grid-column: span 3 / span 3; }
  .md\:u-col-span-4 { grid-column: span 4 / span 4; }

  .md\:u-grid-rows-2 { grid-template-rows: repeat(2, minmax(0, 1fr)); }
  .md\:u-grid-rows-3 { grid-template-rows: repeat(3, minmax(0, 1fr)); }
  .md\:u-grid-rows-4 { grid-template-rows: repeat(4, minmax(0, 1fr)); }

  .md\:u-row-span-2 {	grid-row: span 2 / span 2; }
  .md\:u-row-span-3 {	grid-row: span 3 / span 3; }
  .md\:u-row-span-4 {	grid-row: span 4 / span 4; }
}

@include mq($from: lg) {
  .lg\:u-grid-cols-2 { grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .lg\:u-grid-cols-3 { grid-template-columns: repeat(3, minmax(0, 1fr)); }
  .lg\:u-grid-cols-4 { grid-template-columns: repeat(4, minmax(0, 1fr)); }
  .lg\:u-col-span-1 { grid-column: span 1 / span 1; }

  .lg\:u-gap-8 { gap: 2rem; }
}

@include mq($from: xl) {
  .xl\:u-gap-8 { gap: 2rem; }
}
