.stripe-payment-intents-form {
  margin-bottom: 1rem;
  width: auto !important;

  .payment-form-fields {
    .card-holder {
      margin-bottom: 1rem;

      legend {
        color: #718096; /* tailwind gray 600 */
        font-size: .75rem; /* tailwind text xs */
        font-weight: 700; /* tailwind font bold */
        margin-bottom: .5rem;
        text-transform: uppercase;
      }
    }

    .grid {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      > div {

        input {
          background: #fff;
          border-bottom: 2px solid $gray-400;
          padding: .75rem 0;
        }
      }
    }

    .card-data {
      background: #fff;
      border-bottom: 2px solid $gray-400;
      margin-bottom: .25rem;
      padding: .75rem 0;
    }
  }

  .card-errors {
    color: #eb1c26; /* this red color is from stripe elements ui */
    font-size: .875rem;
    font-weight: 600;
  }
}
