.u-marginTl {
  margin-top: $global-spacing-unit-lg !important;
}

.u-marginTs,
.u-marginVs {
  margin-top: $global-spacing-unit-sm !important;
}

.u-marginBs,
.u-marginVs {
  margin-bottom: $global-spacing-unit-sm !important;
}

.u-flushT { padding-top: 0 !important; }
.u-flushR { padding-right: 0 !important; }
.u-flushH { padding-left: 0 !important; padding-right: 0 !important; }
.u-flushV { padding-bottom: 0 !important; padding-top: 0 !important; }

/* Margin horizontal */
.u-mx-1 { margin-left: .25rem; margin-right: .25rem; }
.u-mx-auto { margin-left: auto; margin-right: auto; }

/* Margin vertical */
.u-my-8 { margin-bottom: 2rem; margin-top: 2rem; }

/* Margin Top */
.u-mt-1 { margin-top: .25rem; }
.u-mt-2 { margin-top: .5rem; }
.u-mt-4 { margin-top: 1rem; }
.u-mt-5,
.u-my-5 { margin-top: 1.25em; }
.u-mt-6 { margin-top: 1.5rem; }
.u-mt-8 { margin-top: 2rem; }
.u-mt-12 { margin-top: 3rem; }
.u-mt-16 { margin-top: 4rem; }

.u-mt-auto { margin-top: auto; }

/* Margin Right */
.u-mr-0 { margin-right: 0; }
.u-mr-1 { margin-right: .25rem; }
.u-mr-2 { margin-right: .5rem; }
.u-mr-3 { margin-right: .75rem; }
.u-mr-4 { margin-right: 1rem; }
.u-mr-5 { margin-right: 1.25rem; }
.u-mr-6 { margin-right: 1.5rem; }
.u-mr-8 { margin-right: 2rem; }

@include mq($from: sm) {
  .sm\:u-mr-4 { margin-right: 1rem; }
  .sm\:u-mr-8 { margin-right: 2rem; }
}

/* Margin Bottom */
.u-mb-0 { margin-bottom: 0; }
.u-mb-1 { margin-bottom: .25rem; }
.u-mb-2 { margin-bottom: .5rem; }
.u-mb-3 { margin-bottom: .75rem; }
.u-mb-4 { margin-bottom: 1rem; }

.u-mb-5,
.u-my-5 { margin-bottom: 1.25rem }
.u-mb-6 { margin-bottom: 1.5rem; }

.u-mb-8,
.u-my-8 { margin-bottom: 2rem; }
.u-mb-10 { margin-bottom: 2.5rem; }
.u-mb-12 { margin-bottom: 3rem; }

.u-mb-auto { margin-bottom: auto; }
.u-mb-px { margin-bottom: 1px; }

@include mq($from: sm) {
  .sm\:u-mb-0 { margin-bottom: 0; }
}

.u--mb-5 { margin-bottom: -1rem; }

/* margin left */
.u-ml-auto { margin-left: auto; }
.u-ml-1 { margin-left: .25rem; }
.u-ml-2 { margin-left: .5rem; }
.u-ml-4 { margin-left: 1rem; }

@include mq($from: sm) {
  .sm\:u-ml-4 { margin-left: 1rem; }
}

/* margin vertical */
.u-my-4 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

/* Negative Margin
   ========================================================================== */
/* top */
.u--mt-1 { margin-top: -.25rem; }
/* right */
.u--mr-1 { margin-right: -.25rem; }
/* bottom */
.u--mb-8 { margin-bottom: -2rem; }

/* horizontal */
.u--mx-1 { margin-left: -.25rem; margin-right: -.25rem; }
.u--mx-2 { margin-left: -.5rem; margin-right: -.5rem; }
.u--mx-4 { margin-left: -1rem; margin-right: -1rem; }

/* left */
.u--ml-1 { margin-left: -.25rem; }
.u--ml-2 { margin-left: -.5rem; }


/* Padding all */
.u-p-0 { padding: 0; }
.u-p-1 { padding: .25rem; }
.u-p-2 { padding: .5rem; }
.u-p-3 { padding: .75rem; }
.u-p-4 { padding: 1rem; }
.u-p-5 { padding: 1.25rem; }
.u-p-6 { padding: 1.5rem; }
.u-p-8 { padding: 2rem; }
.u-p-10 { padding: 2.5rem; }
.u-p-12 { padding: 3rem; }
.u-p-16 { padding: 4rem; }
.u-p-20 { padding: 5rem; }
.u-p-24 { padding: 6rem; }
.u-p-32 { padding: 8rem; }
.u-p-px { padding: 1px; }

@include mq($from: sm) {
  .sm\:u-p-4 { padding: 1rem; }
}

/* Padding x-axis (horizontal) */

.u-px-0 {
  padding-left: 0;
  padding-right: 0;
}

.u-px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.u-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.u-px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.u-px-4 { padding-left: 1rem; padding-right: 1rem; }

.u-px-5 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.u-px-6 { padding-left: 1.5rem; padding-right: 1.5rem; }

.u-px-7 {
  padding-left: 1.728rem;
  padding-right: 1.728rem;
}

.u-px-8 {
  padding-left: 2.488rem;
  padding-right: 2.488rem;
}

.u-px-9 {
  padding-left: 3.583rem;
  padding-right: 3.583rem;
}

.u-px-10 {
  padding-left: 3.583rem;
  padding-right: 3.583rem;
}

/* Padding y-axis (vertical) */
.u-py-1 { padding-bottom: .25rem; padding-top: .25rem; }
.u-py-2 { padding-bottom: .5rem; padding-top: .5rem; }
.u-py-3 { padding-bottom: .75rem; padding-top: .75rem; }
.u-py-4 {
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.u-py-6 { padding-bottom: 1.5rem; padding-top: 1.5rem; }

.u-py-7 {
  padding-bottom: 1.728rem;
  padding-top: 1.728rem;
}

.u-py-8 {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.u-py-9 {
  padding-bottom: 3.583rem;
  padding-top: 3.583rem;
}

.u-py-10 { padding-bottom: 2.5rem; padding-top: 2.5rem; }
.u-py-12 { padding-bottom: 3rem; padding-top: 3rem; }
.u-py-16 { padding-bottom: 4rem; padding-top: 4rem; }
.u-py-20 { padding-bottom: 5rem; padding-top: 5rem; }

/* Padding top */
.u-pt-0,
.u-py-0 { padding-top: 0; }

.u-pt-1 { padding-top: .25rem; }
.u-pt-2 { padding-top: .5rem; }

.u-pt-4 { padding-top: 1rem; }

.u-pt-5,
.u-py-5 { padding-top: 1rem; }
.u-pt-8 { padding-top: 2rem; }
.u-pt-12 { padding-top: 3rem; }
.u-pt-10 { padding-top: 2.5rem; }

/* Padding right */
.u-pr-4 { padding-right: 1rem; }
.u-pr-5 { padding-right: 1.25rem; }
.u-pr-8 { padding-right: 2rem; }
.u-pr-12 { padding-right: 3rem; }
.u-pr-24 { padding-right: 6rem; }
.u-pr-32 { padding-right: 8rem; }

@include mq($from: xs) {
  .xs\:u-pr-24 { padding-right: 6rem; }
}

@include mq($from: xl) {
  .xl\:u-pr-0 { padding-right: 0; }
  .xl\:u-pr-24 { padding-right: 6rem; }
}

/* Padding bottom */
.u-pb-0 { padding-bottom: 0; }
.u-pb-2 { padding-bottom: .5rem; }
.u-pb-4 { padding-bottom: 1rem; }
.u-pb-5,
.u-py-5 { padding-bottom: 1.25em; }
.u-pb-8 { padding-bottom: 2rem; }
.u-pb-10 { padding-bottom: 2.5rem; }

/* Padding left */
.u-pl-2 { padding-left: .5rem; }
.u-pl-4 { padding-left: 1rem; }
.u-pl-5 { padding-left: 1.25rem; }
.u-pl-6 { padding-left: 1.5rem; }
.u-pl-24 { padding-left: 6rem; }

@include mq($from: sm) {
  .sm\:u-mt-16 { margin-top: 4rem; }
  .sm\:u-px-4 { padding-left: 1rem; padding-right: 1rem; }
}

@include mq($from: md) {
  .md\:u-mr-5,
  .md\:u-mx-5 { margin-right: 1rem; }

  .md\:u--mx-6 { margin-left: -1.5rem; margin-right: -1.5rem; }

  .md\:u-mt-0 { margin-top: 0; }

  .md\:u-mr-4 { margin-right: 1rem; }

  .md\:u-mb-0 { margin-bottom: 0; }
  .md\:u-mb-8 { margin-bottom: 2rem; }
  .md\:u-mb-16 { margin-bottom: 4rem; }
  .md\:u-mb-24 { margin-bottom: 6rem; }

  .md\:u--mt-24 { margin-top: -6rem; }

  .md\:u-p-5 { padding: 1.25rem; }
  .md\:u-p-6 { padding: 1.5rem; }
  .md\:u-p-8 { padding: 2rem; }
  .md\:u-p-12 { padding: 3rem; }

  .md\:u-px-6 { padding-left: 1.5rem; padding-right: 1.5rem; }
  .md\:u-px-8 { padding-left: 2rem; padding-right: 2rem; }

  .md\:u-py-12 { padding-top: 3rem; padding-bottom: 3rem; }

  .md\:u-pt-5,
  .md\:u-py-5 { padding-top: 1.25rem; }

  .md\:u-pb-5,
  .md\:u-py-5 { padding-bottom: 1.25rem; }

  .md\:u-pb-7 { padding-bottom: 1.728rem; }

  .md\:u-pr-8 { padding-right: 2rem; }
}

@include mq($from: lg) {
  .lg\:u-p-4 { padding: 1rem; }
  .lg\:u-p-6 { padding: 1.5rem; }
  .lg\:u-p-8 { padding: 2rem; }

  .lg\:u-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:u-py-8 {
    padding-bottom: 2.488rem;
    padding-top: 2.488rem;
  }

  .lg\:u-py-10 {
    padding-bottom: 3.583rem;
    padding-top: 3.583rem;
  }

  .lg\:u-pb-0 { padding-bottom: 0; }
  .lg\:u-pl-0 { padding-left: 0; }

  .lg\:u-mr-8 { margin-right: 2rem; }

  .lg\:u-mb-8 { margin-bottom: 2rem; }
}


@include mq($from: xl) {
  .xl\:u-p-4 { padding: 1rem; }
  .xl\:u-px-4 { padding-left: 1rem; padding-right: 1rem; }
  .xl\:u-px-10 {
    padding-left: 3.583em;
    padding-right: 3.583em;
  }
}
