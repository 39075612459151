.u-bg-transparent { background-color: transparent; }
/* Black */
.u-bg-black { background-color: #000; }
/* White */
.u-bg-white { background-color: #fff; }

/* Blue */

.u-bgBlueLinear {
  background: linear-gradient(to bottom, $blue-green, $blue);
}

.u-bg-blue-100 { background-color: $blue-100; }
.u-bg-blue-200 { background-color: $blue-200; }
.u-bg-blue-400 { background-color: $blue-400; }
.u-bg-blue,
.u-bg-blue-500 { background-color: $blue-500; }
.u-bg-blue-600 { background-color: $blue-600; }
.u-bg-blue-700 { background-color: $blue-700; }
.u-bg-blue-800 { background-color: $blue-800; }
.u-bg-blue-900 { background-color: $blue-900; }

.u-bg-blue-90 {
  background-color: rgba($blue, .9);
}

.u-bg-blue-light {
  background-color: lighten($blue, 40%);
}

/* Body */
.u-bg-body { background-color: $global-body-background; }

/* Green */
.u-bg-green-bright {
  background-color: $bright-green;
}

/* Grey */
.u-bg-grey-900,
.u-bg-gray-900 { background-color: $gray-900; }
.u-bg-grey-800,
.u-bg-gray-800 { background-color: $gray-800; }
.u-bg-grey-700 { background-color: $gray-700; }
.u-bg-grey-600,
.u-bg-grey-dark,
.u-bg-gray-600 { background-color: $gray-600; }
.u-bg-gray-500,
.u-bg-grey-500,
.u-bg-grey { background-color: $gray-500; }
.u-bg-grey-400,
.u-bg-grey-light,
.u-bg-gray-400 { background-color: $gray-400; }
.u-bg-grey-300,
.u-bg-gray-300 { background-color: $gray-300; }
.u-bg-grey-200,
.u-bg-grey-lighter,
.u-bg-gray-200 { background-color: $gray-200; }
.u-bg-grey-100,
.u-bg-grey-lightest,
.u-bg-gray-100 { background-color: $gray-100; }

/* Indigo */

.u-bg-indigo-dark {
  background-color: $deep-indigo;
}

.hover\:u-bg-indigo-dark:hover { background-color: $deep-indigo; }

.u-bg-indigo-500 { background: $indigo-500; }
.u-bg-indigo,
.u-bg-indigo-900 { background-color: $indigo-900; }

.u-bg-indigo-linear {
  background: linear-gradient(rgba($indigo, 0), rgba($indigo, 1));
}

/* Orange */

.u-bg-orange-400 { background-color: $orange-400; }
.u-bg-orange,
.u-bg-orange-500 { background-color: $orange-500; }
.u-bg-orange-600 { background-color: $orange-600; }

.u-bg-orange-90 {
  background-color: rgba($orange, .9);
}

/* Purple */

.u-bg-purple {
  background-color: $purple;
}

.u-bg-purple-90 {
  background-color: rgba($purple, .9);
}

/* Red */
.u-bg-red,
.u-bg-red-500 { background-color: $red-500; }
.u-bg-red-600 { background-color: $red-600; }
.u-bg-red-90 { background-color: rgba($red, .9); }

/* yellow */
.u-bg-yellow-100 { background-color: $yellow-100; }
.u-bg-yellow-400 { background-color: $yellow; }
.u-bg-yellow-200 { background-color: $yellow-200; }

/* green */
.u-bg-green-400 { background-color: $green-400; }
.u-bg-green-500 { background-color: $green-500; }
.u-bg-green-600 { background-color: $green-600; }

/* pink */
.u-bg-pink-900 { background-color: $pink-900; }




/* =============================================================================
   Hover styles
   ========================================================================== */
.hover\:u-bg-white:hover { background: #fff; }
.hover\:u-bg-grey-100:hover,
.hover\:u-bg-gray-100:hover { background-color: $gray-100; }
.hover\:u-bg-grey-200:hover,
.hover\:u-bg-gray-200:hover { background-color: $gray-200; }
.hover\:u-bg-grey-300:hover { background-color: $gray-300; }

.hover\:u-bg-blue-100:hover { background-color: $blue-100; }
.hover\:u-bg-blue-200:hover { background-color: $blue-200; }
.hover\:u-bg-blue-400:hover { background-color: $blue-400; }


/* =============================================================================
   Focus styles
   ========================================================================== */
.focus\:u-bg-grey-200:focus { background: $grey-200; }


/* =============================================================================
   Active styles
   ========================================================================== */
.active\:u-bg-grey-300:active { background: $grey-300; }


@include mq($from: sm) {
  .sm\:u-bg-transparent { background-color: transparent; }
}
