.o-HorizontalList {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
}

.o-HorizontalList-title,
.o-HorizontalList-item {
  padding: 0 .833em .335em 0;
}

.o-HorizontalList-title {
  padding-right: .402em;
}

.o-HorizontalList--widen {
  .o-HorizontalList-item {
    padding-right: 1.2em;
  }
}
