.o-PlanGroup {
  display: flex;
  flex-flow: column wrap;

  .c-Plan {
    width: 100%;
  }
}

@include mq($from: sm) {
  .o-PlanGroup {
    flex-flow: row nowrap;

    .c-Plan {
      width: inherit;

      + .c-Plan {
        margin-left: -1em;
      }
    }
  }
}
