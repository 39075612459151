/* =============================================================================
   #PROFILE
   ========================================================================== */
$badge-width: 19px;

.c-Profile {

}

.c-Profile-header {
  background: $indigo;
  color: #fff;
  position: relative;

  a {
    color: inherit;
  }

  .c-Icon {
    fill: $grey-light;
  }
}

.c-Profile-body {
  margin: 0 auto;
  max-width: $global-content-width;
}

.c-Profile-cover {
  display: block;
  overflow: hidden;
  position: relative;

  &::before {
    background: linear-gradient(to top, rgba($indigo, .75) 0%, rgba($indigo, 0) 100%);
    bottom: 0;
    content: '';
    display: block;
    height: 100px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  &::after {
    background: linear-gradient(to bottom,
    rgba($indigo,0) 49%,
    rgba($indigo,.2) 49%, rgba($indigo,.2) 61%,
    rgba($indigo,.5) 61%, rgba($indigo,.5) 73%,
    rgba($indigo,.95) 73%);
    bottom: 0;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(5deg);
    transform-origin: left top;
    z-index: 1;
  }
}

.c-Profile-coverImage {
  display: block;
  padding-bottom: 56.25%;
  width: 100%;

  img {
    bottom: 0;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.c-Profile-hGroup {
  bottom: $global-spacing-unit;
  left: 0;
  margin: 0;
  padding: 0 $global-spacing-unit 0 calc(80px + #{$global-spacing-unit} + #{$global-spacing-unit});
  position: absolute;
  z-index: 2;
  width: 100%;
}

.c-Profile-title {
  font-family: $display-font-family;
  font-size: 2.074em;
  margin: 0;
}

.c-Profile-badges {
  display: inline-block;
  font-size: 1rem;
  margin-right: -$badge-width;
  vertical-align: middle;

  .c-Icon {
    margin-right: 4px;
  }
}

.c-Profile-navBar {
  background: #fff;
  margin-bottom: $global-spacing-unit;
  padding: $global-spacing-unit-sm;
  position: relative;
}

.c-Profile-image {
  left: $global-spacing-unit;
  margin: 0;
  position: absolute;
  top: calc(-80px - #{$global-spacing-unit});
  width: 80px;
  z-index: 1;
}

@include mq($from: md) {
  .c-Profile-cover {
    height: 400px;
    padding: 0;
  }

  .c-Profile-title {
    font-size: 2.986em;
  }

  .c-Profile-hGroup,
  .c-Profile-navBar {
    padding-left: calc(175px + (#{$global-spacing-unit} + #{$global-spacing-unit});
  }

  .c-Profile-image {
    border: 6px solid $global-body-background;
    bottom: $global-spacing-unit-sm;
    top: auto;
    width: 175px;
  }
}

@include mq($from: lg) {
  .c-Profile-body {
    display: flex;
    max-width: none;
  }

  .c-Profile-hGroup,
  .c-Profile-navBar {
    padding-left: calc(#{$content-meta-width} + #{$content-grid-gap-lg} + #{$global-spacing-unit});
  }

  .c-Profile-image {
    bottom: -$global-spacing-unit;
    width: $content-meta-width;
  }

  .c-Profile-main {
    flex: 1;
  }

  .c-Profile-aside {
    width: 300px;
  }
}

@include mq($from: 70em) {
  .c-Profile-main {
    display: flex;
  }

  .c-Profile-meta {
    padding: 0 1em;
    width: calc(200px + 1em + 1em);
  }

  .c-Profile-feed {
    flex: 1;
  }
}

@include mq($from: 80em) {
  .c-Profile-aside {
    width: 400px;
  }
}
