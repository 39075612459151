[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
  background: none;
  border: none;
  border-radius: 0;
  display: block;
  font-family: inherit;
  font-size: 1em;
  height: $global-input-height;
  padding: 0;
  transition: border-color .3s ease-in-out;
  width: 100%;

  &:focus {
    outline: none;
    border-bottom-color: currentColor;
  }
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,input,optgroup,select,textarea{
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0 /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  &:not([disabled]) {
    cursor: pointer;
  }
}

textarea {
  max-width: 100%;
}

/*textarea[rows] {
  background: $grey-lightest;
  height: auto;
  padding: .335em;
}*/

input::placeholder,
textarea::placeholder {
  color: $gray-600;
}

input:required::placeholder,
textarea:required::placeholder {
  color: $gray-800;
}

label {
  cursor: pointer;
}
