.c-SkewBlock {
  padding: 1em;
  position: relative;

  > * {
    position: relative;
  }

  &::before {
    background: $grey-lightest;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    transform: skewX(-10deg);
    top: 0;
    width: 100%;
  }

  #verified-features & {
    display: inline-block;
  }
}
