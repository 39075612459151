.u-hidden { display: none; }
.u-inline-block { display: inline-block; }
.u-flex { display: flex; }
.u-inline-flex { display: inline-flex; }
.u-grid { display: grid; }
.u-block { display: block; }

@include mq($from: sm) {
  .sm\:u-block { display: block; }
  .sm\:u-inline { display: inline; }
  .sm\:u-flex { display: flex; }
}

@include mq($from: md) {
  .md\:u-hidden { display: none; }
  .md\:u-block { display: block; }
  .md\:u-flex { display: flex; }
}

@include mq($from: lg) {
  .lg\:u-flex { display: flex; }
  .lg\:u-grid { display: grid; }
  .lg\:u-block { display: block; }
  .lg\:u-hidden { display: none; }
}

@include mq($from: xl) {
  .xl\:u-flex { display: flex; }
}
