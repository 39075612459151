/**
 * <div class="c-tabs">
 *   <input class="c-tabs__input" type="radio" id="tab1" checked>
 *   <label class="c-tabs__label" for="tab1">
 *   <div class="c-tabs__panel"></div>
 * </div>
 */

.c-tabs__input,
.c-tabs__panel {
  display: none;
}

.c-tabs {
  display: flex;
  flex-wrap: wrap;
}

.c-tabs__label {
  display: block;
  padding: 1em;
}

.c-tabs__panel {
  border-top: 1px solid $grey-lighter;
  order: 1;
  padding: 1em;
  width: 100%;
}

.c-tabs__input:checked {
  + .c-tabs__label {
    border-bottom: 3px solid;
    font-weight: $global-bold-weight;

    + .c-tabs__panel {
      display: block;
    }
  }
}
