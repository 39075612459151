/* =============================================================================
   #HERO
   ========================================================================== */

/**
 * Simple content pattern that places content ontop of background figure
 */

.o-Hero {
  display: block;
  overflow: hidden;
  position: relative;
}

.o-Hero-content {
  position: relative;
  z-index: 1;
}

.o-Hero-figure {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.o-Hero-asset {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
