.c-Listing {
  color: $grey-darker;
  display: flex;
  padding: .335rem 0;

  &:last-child {
    flex: 1;
  }
}

.c-Listing--verified {
  color: $global-text-color;
  padding-bottom: 0;
  padding-top: 0;

  &:first-of-type {
    padding-top: .335rem;
  }

  .c-Listing-layout {
    background: lighten($yellow, 30%);
    border-bottom: 1px solid $grey;
    min-height: calc(80px + 1rem + 1rem + 1px);
  }

  .c-Listing-title {
    font-weight: $global-bold-weight;
  }
}

.c-Listing-layout {
  background: #fff;
  box-shadow: $global-feather-shadow;
  color: inherit;
  display: flex;
  padding: 1rem;
  text-decoration: none;
  flex: 1;
}

.c-Listing-image {
  display: block;
  padding-right: 1rem;
  width: 60px;

  img {
    border: 2px solid $grey-lighter;
  }
}

.c-Listing-body {
  flex: 1;
  padding-left: 0;
}

.c-Listing-header {
  color: inherit;
  display: block;
  text-decoration: none;
}

.c-Listing-meta {
  font-size: .833em;
}

.c-Listing-title {
  font-family: $display-font-family;
  font-weight: 500;
  line-height: 1;
  margin-bottom: .335rem;
}

@include mq($from: md) {
  .o-Listings {
    display: flex;
    flex-wrap: wrap;
  }

  .c-Listing {
    flex-basis: auto;
    padding-left: .335em;
    padding-right: .335rem;
    width: 50%;
  }

  .c-Listing--verified {
    width: 100%;

    .c-Listing-image {
      width: auto;
    }
  }
}

@include mq($from: lg) {
  .c-Listing-image {
    width: auto;
  }

  .c-Listing-body {
    display: flex;
  }

  .c-Listing-header {
    flex: 1;
    padding-right: $global-spacing-unit;

    > :last-child {
      margin-bottom: 0;
    }
  }

  .c-Listing-meta {
    flex-basis: auto;
    width: 245px;
  }
}
