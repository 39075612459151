.u-shadow-sm { box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); }

.u-shadow { box-shadow: 0 2px 4px 0 rgba(0,0,0,.1); }

.u-shadow-md { box-shadow: 0 4px 8px 0 rgba(0,0,0,.12), 0 2px 4px 0 rgba(0,0,0,.08); }
.u-shadow-lg { box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); }
.u-shadow-none { box-shadow: none; }

.hover\:u-shadow-lg:hover { box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); }

/* =============================================================================
   Focus styles
   ========================================================================== */
.focus\:u-shadow-outline:focus { box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); }
