/* ==========================================================================
   #CREDIT
   ========================================================================== */

/**
 * This utility class provides styling on text that serves as a credit to the
 * asset near it. Example: photo credit.
 */
.u-credit {
  color: $grey;
  font-size: .833em;
}