.c-Header {
  background: $deep-indigo;
  //position: sticky;
  top: 0;

  > .o-container {
    align-items: center;
    display: flex;
  }
}

.c-Header-logo {
  display: block;
  fill: $deep-indigo;
  padding: .75rem .75rem .75rem .25rem;
  position: relative;
  width: 6rem;

  &::before {
    background: $yellow;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: skewX(-10deg);
    transform-origin: left top;
    width: 100%;
    z-index: 0;
  }
}

.c-Header-svg {
  fill: $indigo;
  display: block;
  position: relative; // places svg above psuedo background
}

@include mq($from: md) {
  .c-Header-logo {
    padding: .75rem 1rem .75rem .5rem;
    width: 180px;
  }
}
