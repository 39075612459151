

@include mq($from:55em) {
  .o-Cols {
    display: flex;
  }

  .o-Cols-main {
    flex: 1;
  }

  .o-Cols-sidebar {
    margin-left: 2.074em;
    width: 300px;
  }
}
