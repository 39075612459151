.c-Card {
  background: #fff;
  box-shadow: $global-feather-shadow;
  overflow: hidden;
}

.c-Card-header,
.c-Card-section,
.c-Card-footer {
  color: inherit;
  display: block;
  padding: 9px;

  @at-root {
    a#{&} {
      text-decoration: none;
    }
  }

  .c-Card-armband {
    margin-left: -9px;
    margin-right: -9px;
  }
}

.c-Card-header {
  h1,h2,h3,h4,h5,h6 {
    margin: 0;
  }

  + .c-Card-footer {
    padding-top: 0;
  }
}

.c-Card-header--highlight {
  background: $green;
}

.c-Card-section {
  > :last-child {
    margin-bottom: 0;
  }
}

.c-Card-footer {
  border-top: 1px solid $grey-lighter;
  padding-top: $global-font-size;
  padding-bottom: $global-font-size;
}

.c-Card-armband {
  margin-bottom: $global-spacing-unit;
}

.c-Card--showcase {
  background: $indigo;
  color: #fff;
}

@include mq($from: sm) {
  .c-sm-Card--inline {
    display: flex;

    .c-Card-header {
      display: flex;
      flex-flow: column;
      justify-content: center;
      max-width: 350px;
      text-align: center;
      width: 50%;
    }

    .c-Card-body {
      flex: 1;
    }
  }
}
