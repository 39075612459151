.c-spinner,
.c-spinner::before,
.c-spinner::after {
  width: .75rem;
  height: .75rem;
  border-radius: 100%;
  display: inline-block;
  animation: bounce 1.8s infinite ease-in-out both;
  vertical-align: middle;
}

.c-spinner {
  color: #fff;
  font-size: 1rem;
  margin: 0 auto 0;
  position: relative;
  top: -.75rem;
  animation-delay: -0.16s;
  text-indent: -9999em;
  transform: translateZ(0);
}

.c-spinner::before,
.c-spinner::after {
  content: '';
  position: absolute;
  top: 0;
}

.c-spinner::before {
  left: -1.5rem;
  animation-delay: -0.32s;
}

.c-spinner::after {
  left: 1.5rem;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    box-shadow: 0 .75rem 0 -.5rem;
  }
  40% {
    box-shadow: 0 .75rem 0 0;
  }
}
