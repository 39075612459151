/* Border Width
   ========================================================================== */

/* Border All */
.u-border-0 {
  border-width: 0;
}
.u-border { border-width: 1px; }
.u-border-2 { border-width: 2px; }
.u-border-4 { border-width: 4px; }
.u-border-8 { border-width: 8px; }

/* Border top */
.u-border-t { border-top-width: 1px; }
.u-border-t-0 { border-top-width: 0px; }
.u-border-t-2 { border-top-width: 2px; }
.u-border-t-3 { border-top-width: 3px; }
.u-border-t-4 { border-top-width: 4px;}

/* Border right */
.u-border-r {
  border-right-width: 1px;
}

/* Bottom */
.u-border-b { border-bottom-width: 1px; }
.u-border-b-2 { border-bottom-width: 2px; }
.u-border-b-4 { border-bottom-width: 4px; }

/* Left */
.u-border-l { border-left-width: 1px; }


/* Border Color
   ========================================================================== */
.u-border-transparent { border-color: transparent; }

.u-border-blue-400 { border-color: $blue-400; }
.u-border-blue { border-color: $blue; }
.hover\:u-border-blue-400:hover { border-color: $blue-400; }

.u-border-grey-200, .u-border-grey-lighter, .u-border-gray-200 { border-color: $grey-200; }
.u-border-grey-300, .u-border-grey-light, .u-border-gray-300 { border-color: $grey-300; }
.u-border-grey-400, .u-border-gray-400 { border-color: $grey-400; }
.u-border-gray-500 { border-color: $gray-500; }
.u-border-gray-600 { border-color: $gray-600; }
.u-border-gray-700 { border-color: $gray-700; }
.u-border-grey-800, .u-border-gray-800 { border-color: $grey-800; }
.hover\:u-border-gray-500:hover { border-color: $gray-500; }
.hover\:u-border-gray-600:hover { border-color: $gray-600; }
.focus\:u-border-gray-500:focus { border-color: $grey-500; }

.u-border-orange {
  border-color: $orange;
}

.u-border-purple {
  border-color: $purple;
}

.u-border-red {
  border-color: $red;
}

.u-border-indigo { border-color: $indigo; }
.u-border-indigo-300 { border-color: $indigo-300; }

.u-border-white { border-color: #fff; }

.u-border-blue-300 { border-color: $blue-300; }
.u-border-blue-400 { border-color: $blue-400; }


/* Border Style
   ========================================================================== */
.u-border-solid {
  border-style: solid;
}

/* Border radius
   ========================================================================== */
.u-rounded-none { border-radius: 0; }
.u-rounded { border-radius: .25rem; }
.u-rounded-lg { border-radius: .5rem; }
.u-rounded-full { border-radius: 9999px; }

.u-rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.u-rounded-b-lg {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}



/* =============================================================================
   Focus styles
   ========================================================================== */
.focus\:u-border-grey-500:focus { border-color: $grey-500; }
