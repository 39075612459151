/* =============================================================================
   #ICONS
   ========================================================================== */

.c-icon,
.c-Icon {
  background: transparent;
  display: inline-block;
  fill: currentColor;
  font-style: normal;
  position: relative;
  vertical-align: baseline;
}

.c-icon--inline {
  vertical-align: text-top;
}


.c-icon-sign {
  background: rgba(255,255,255,.5);
  border-radius: 50px;
  border: 7px solid;
}
