.o-DisplayCard {
  background: #fff;
  border-top: 2px solid $grey-lighter;
  margin-bottom: $global-spacing-unit;

  .c-DisplayTitle {
    margin: 0;
  }
}

.o-DisplayCard-header,
.o-DisplayCard-footer,
.o-DisplayCard-inner {
  padding: .833em $global-spacing-unit;
}

.o-DisplayCard-inner {
  padding-bottom: .482em;
  padding-top: 0;
}

.o-DisplayCard-footer {
  border-bottom: none;
  border-top: 1px solid $grey-lighter;
}

.o-DisplayCard-inner--flushR {
  padding: 0;
}

@include mq($from: md) {
  /*.o-DisplayCard {
    display: flex;
    flex-wrap: wrap;
  }

  .o-DisplayCard-header {
    max-width: 150px;
    width: 33%;
  }

  .o-DisplayCard-body {
    flex: 1;
    min-width: 0; /* owl carousel hack *
  }

  .o-DisplayCard-inner {
    padding-left: 0;
  }

  .o-DisplayCard-footer {
    width: 100%;
  }*/
}
