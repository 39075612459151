.u-flex-wrap {
  flex-wrap: wrap;
}

/* Direction
   ========================================================================== */
.u-flex-col {
  flex-direction: column;
}

.u-flex-col-reverse { flex-direction: column-reverse; }

/* Justify Content
   ========================================================================== */
.u-justify-start { justify-content: flex-start; }
.u-justify-center { justify-content: center; }
.u-justify-end { justify-content: flex-end; }
.u-justify-around { justify-content: space-around; }
.u-justify-between { justify-content: space-between; }

/* Align Items
   ========================================================================== */
.u-items-stretch { align-items: stretch; }
.u-items-center { align-items: center; }
.u-items-start { align-items: flex-start; }
.u-items-end { align-items: flex-end; }

/* Flex, Shrink, Grow
   ========================================================================== */
.u-flex-1 { flex: 1; }
.u-flex-none	{ flex: none; }

@include mq($from: md) {
  .md\:u-flex-1 { flex: 1; }
}

.u-order-first { order: -9999; }
.u-order-last { order: 9999; }



@include mq($from: sm) {
  .u-sm\: {
    &flex {
      display: flex;
    }
  }

  .sm\:u-flex-row { flex-direction: row; }
}


@include mq($from: md) {
  .md\:u-flex { display: flex; }
  .md\:u-flex-col { flex-direction: column; }
  .md\:u-flex-row { flex-direction: row; }
  .md\:u-flex-row-reverse { flex-direction: row-reverse; }
  .md\:u-items-center { align-items: center; }

  .md\:u-justify-end { justify-content: flex-end; }

  .md\:u-order-none { order: 0; }
}


@include mq($from: lg) {
  .lg\:u-flex-col { flex-direction: column; }
  .lg\:u-flex-row { flex-direction: row; }

  .lg\:u-items-center { align-items: center; }

  .lg\:u-flex-1 { flex: 1; }
}

@include mq($from: xl) {
  .xl\:u-flex-col { flex-direction: column; }
}
