.u-textSmall {
  font-size: .833em;
}


/* Fonts
   ========================================================================== */
.u-font-serif { font-family: $serif-font-family; }


/* Colors
   ========================================================================== */
.u-text-current { color: currentColor; }

.u-text-blue-700 { color: $blue-700; }
.u-text-blue,
.hover\:u-text-blue:hover { color: $blue; }

/* Grey */

.u-text-grey-darker,
.hover\:u-text-grey-darker:hover {
  color: $grey-800;
}

.u-text-white { color: #fff; }
.u-text-black { color: #000; }

.u-text-grey-100, .u-text-gray-100 { color: $grey-100; }
.u-text-grey-200, .u-text-grey-lighter, .u-text-gray-200 { color: $grey-200; }
.u-text-grey-300, .u-text-grey-light, .u-text-gray-300 { color: $grey-300; }
.u-text-grey-400, .u-text-gray-400 { color: $grey-400; }
.u-text-grey-500, .u-text-grey, .u-text-gray-500 { color: $grey-500; }
.u-text-grey-600, .u-text-gray-600 { color: $grey-600; }
.u-text-grey-700, .u-text-grey-dark, .u-text-gray-700 { color: $grey-700; }
.u-text-grey-800, .u-text-gray-800 { color: $grey-800; }
.u-text-grey-900, .u-text-gray-900 { color: $grey-900; }

.group:hover .group-hover\:u-text-grey-500 { color: $grey-500; }

.hover\:u-text-current:hover { color: currentColor; }


/* Indigo */
.u-text-indigo-100 { color: $indigo-100; }
.u-text-indigo-300 { color: $indigo-300; }
.u-text-indigo-500 { color: $indigo-500; }
.u-text-indigo-900, .u-text-indigo { color: $indigo-900; }

.u-text-orange-100 { color: $orange-100; }
.u-text-orange-400 { color: $orange-400; }
.u-text-orange,
.u-text-orange-700 { color: $orange-700; }
.u-text-orange-900 { color: $orange-900; }

.u-text-yellow-200 { color: $yellow-200; }
.u-text-yellow-300 { color: $yellow-300; }

.u-text-green-50 { color: $green-50; }
.u-text-green-100 { color: $green-100; }
.u-text-green-200 { color: $green-200; }
.u-text-green-400 { color: $green-400; }
.u-text-green-600 { color: $green-600; }
.u-text-green-700 { color: $green-700; }
.u-text-green-900 { color: $green-900; }

.u-text-purple,
.u-text-purple-700 { color: $purple; }

.u-text-red-100 {color: $red-100; }
.u-text-red,
.u-text-red-500 { color: $red; }
.u-text-red-600 { color: $red-600; }
.u-text-red-700 { color: $red-700; }

.u-text-pink-300 { color: #fbb6ce; }

.hover\:u-text-white:hover { color: #fff; }

.u-text-blue-700 { color: $blue-700; }
.u-text-blue-800 { color: $blue-800; }
.u-text-blue-900 { color: $blue-900; }


/* Sizing
   ========================================================================== */
.u-text-xs { font-size: .75rem; }
.u-text-sm { font-size: .875rem; }
.u-text-base { font-size: 1rem; }
.u-text-lg { font-size: 1.125rem; }
.u-text-xl { font-size: 1.25rem; }
.u-text-2xl { font-size: 1.5rem; }
.u-text-3xl { font-size: 1.875rem; }
.u-text-4xl { font-size:2.25rem; }
.u-text-5xl { font-size: 3rem; }
.u-text-6xl { font-size: 4rem; }


/* Font weights
   ========================================================================== */
.u-font-normal { font-weight: 400; }
.u-font-medium { font-weight: 500; }
.u-font-semibold { font-weight: 600; }
.u-font-bold { font-weight: 700; }
.u-font-extrabold { font-weight: 800; }


/* Letter spacing
   ========================================================================== */
.u-tracking-wide { letter-spacing: .025rem; }
.u-tracking-wider { letter-spacing: .05rem; }


/* Alignment
   ========================================================================== */
.u-text-center { text-align: center; }
.u-text-right { text-align: right; }


/* Line height
   ========================================================================== */
.u-leading-none { line-height: 1; }
.u-leading-tight { line-height: 1.25; }
.u-leading-normal { line-height: 1.5; }
.u-leading-loose { line-height: 2; }


/* Lists
   ========================================================================== */
.u-list-reset { margin: 0; padding: 0; }
.u-list-disc { list-style-type: disc; }


/* Measure
   ========================================================================== */
.u-text-narrow {
  max-width: $global-narrow-measure;
}

.u-text-measure {
  max-width: $global-base-measure;
}

.u-text-wide {
  max-width: $global-wide-measure;
}


/* Style & Decoration
   ========================================================================== */
.u-uppercase {
  text-transform: uppercase;
}
.u-lowercase { text-transform: lowercase; }

.u-underline { text-decoration: underline; }
.u-line-through { text-decoration: line-through; }
.u-no-underline { text-decoration: none; }

.hover\:u-underline:hover { text-decoration: underline; }


/* Whitespace and wrapping
   =========================================================================== */
.u-whitespace-no-wrap	{ white-space: nowrap; }

.u-text-truncate {
  max-width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}


@include mq($from: sm) {
  .sm\:u-text-sm { font-size: .875rem; }
  .sm\:u-text-base { font-size: 1rem; }
  .sm\:u-text-xl { font-size: 1.25rem; }
  .sm\:u-text-2xl { font-size: 1.5rem; }
  .sm\:u-text-3xl { font-size: 1.875rem; }
}


@include mq($from: md) {
  .md\:u-text-base { font-size: 1rem; }
  .md\:u-text-lg { font-size: 1.125rem; }
  .md\:u-text-2xl { font-size: 1.5rem; }
  .md\:u-text-3xl { font-size: 1.875rem; }

  .md\:u-text-4xl { font-size: 2.25rem; }
  .md\:u-text-5xl { font-size: 3rem; }
  .md\:u-text-6xl { font-size: 4rem; }

  .md\:u-text-left { text-align: left; }
  .md\:u-text-right { text-align: right; }
}

@include mq($from: lg) {
  .lg\:u-text-sm { font-size: .875rem; }
  .lg\:u-text-lg { font-size: 1.125rem; }
  .lg\:u-text-3xl { font-size: 1.875rem; }
  .lg\:u-text-4xl { font-size: 2.25rem; }
}

@include mq($from: xl) {
  .xl\:u-text-5xl { font-size: 3rem; }
  .xl\:u-text-6xl { font-size: 4rem; }
}
