/* ==========================================================================
   #RESET
   ========================================================================== */
/**
* Allow adding a border to an element by just adding a border-width.
*
* By default, the way the browser specifies that an element should have no
* border is by setting it's border-style to `none` in the user-agent
* stylesheet.
*
* In order to easily add borders to elements by just setting the `border-width`
* property, we change the default border-style for all elements to `solid`, and
* use border-width to hide them instead. This way our `border` utilities only
* need to set the `border-width` property instead of the entire `border`
* shorthand, making our border utilities much more straightforward to compose.
*
* https://github.com/tailwindcss/tailwindcss/pull/116
*/
*,
*::before,
*::after {
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}

/**
 * A very simple reset that sits on top of Normalize.css.
 */

body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin:  0;
  padding: 0;
}

button {
  background: transparent;
  padding: 0;
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 */

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */

fieldset {
  border: 0;
  min-width: 0; /* [1] */
}

iframe {
  border: 0;
}

ol,
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/**
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 */

[tabindex="-1"]:focus {
  outline: none !important;
}


/**
 * Remove trailing margins from nested lists.
 */

li > {

  ol,
  ul {
    margin-bottom: 0;
  }

}


/**
 * Remove default table spacing.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}
