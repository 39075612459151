.c-Seller {
  align-items: center;
  border-bottom: 1px solid $grey-light;
  border-top: 1px solid $grey-light;
  display: flex;
  margin-bottom: 1em;
  padding: .335em 0;
}

.c-Seller-art {
  margin: 0 1em 0 0;
  width: 24px;
}

.c-Seller-body {
  flex: 1;
}

.c-Seller-name {
  font-size: 1em;
  margin: 0;
}

@include mq($from: md) {
  .c-Seller {
    border: none;
    display: block;
    text-align: center;
  }

  .c-Seller-art {
    margin: 0 auto;
    position: relative;
    width: 48px;

    &::before,
    &::after {
      border-top: 1px solid $grey-light;
      content: '';
      display: block;
      height: 1px;
      left: calc(-48px - 16px);
      position: absolute;
      top: 50%;
      width: 100%;
    }

    &::after {
      left: auto;
      right: calc(-48px - 16px);
    }
  }

  .c-Seller-body {
    padding-top: 1em;
  }

  .c-Seller-name {
    font-size: 1.44em;
  }
}
