.o-Listing-item {
  margin-bottom: $global-spacing-unit;

  &:last-child,
  > :last-child {
    margin-bottom: 0
  }
}

.o-Listing--xs .o-Listing-item {
  margin-bottom: $global-spacing-unit-xs;
}
