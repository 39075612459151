.c-Plan {
  border-radius: $global-radius;
  box-shadow: $global-soft-shadow;
  padding: $global-spacing-unit-lg;

  .c-Plan-conjunction {
    margin-left: -$global-spacing-unit-lg;
    margin-right: -$global-spacing-unit-lg;
  }

  @include mq($from: md) {
    padding: $global-spacing-unit-lg $global-spacing-unit-xl;

    .c-Plan-conjunction {
      margin-left: -$global-spacing-unit-xl;
      margin-right: -$global-spacing-unit-xl;
    }
  }
}

.c-Plan-conjunction {
  align-items: center;
  display: flex;
  font-family: $serif-font-family;
  font-size: 1.44em;
  font-style: italic;
  position: relative;
  top: -.482em;

  &::before,
  &::after {
    background: rgba(255,255,255,.3);
    content: '';
    display: block;
    flex: 1;
    height: 4px;
  }

  &::before {
    margin-right: 1em;
  }

  &::after {
    margin-left: 1em;
  }
}

.c-Plan-header {
  text-align: center;
  text-shadow: $global-soft-shadow;
}

.c-Plan-price {
  font-size: 2.986em;
  line-height: 1;
}

.c-Plan-savings {
  color: $yellow;
  font-weight: $global-bold-weight;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 1px 3px rgba(0,0,0,.5);
}

.c-Plan--annual {
  background: linear-gradient(to bottom, $blue-green, $blue);
  color: #fff;
}

.c-Plan--month {
  align-self: center;
  background: linear-gradient(to bottom, $bright-green, $green);
  color: #fff;
  text-align: center;
}
