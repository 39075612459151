/**
 * create horizontal lines that are vertically centered inside the parent
 * container around a main element, like a heading or an image.
 */

.c-line-on-sides {
  align-items: center;
  display: flex;

  > * {
    margin: 0 8px;
  }

  &::before,
  &::after {
    background: $grey-light;
    content: '';
    flex: 1;
    height: 2px;
  }
}
