.c-EntryByline {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: .833em;

  a {
    color: $global-text-color;
    font-weight: $global-bold-weight;
    text-decoration: none;
  }
}

.c-Byline-author,
.c-Byline-date {
  padding: 5px 0;
}

.c-Byline-author {
  margin-right: 1em;
}

.c-Byline-date {
  color: $grey;
  display: block;
  padding-left: 1em;

  &::before {
    content: "/";
    display: inline-block;
    margin-left: -1em;
    text-align: center;
    width: 1em;
  }
}

@include mq($from: md) {
  .c-Byline {
    font-size: 1em;
  }
}
