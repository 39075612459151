.c-headlines {
  display: grid;
  grid-gap: 1rem;
  grid-template-areas:
  "main"
  "secondary"
  "tertiary"
  "list"
}

@include mq($from: sm) {
  .c-headlines {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "main main"
    "secondary tertiary"
    "list list";
    grid-gap: 2rem;
  }
}

@include mq($from: md) {
  .c-headlines{
    grid-template-areas:
    "main main"
    "secondary list"
    "tertiary list"
  }
}

@include mq($from: lg) {
  .c-headlines {
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-areas:
    "secondary main list"
    "tertiary main list";
  }
}

.c-headlines__main {
  display: grid;
  grid-area: main;
  grid-gap: 1rem;
  grid-template-areas:
  "main-image"
  "main-body"
}

@include mq($from: md) {
  .c-headlines__main {
    grid-gap: 2rem;
  }
}

.c-headlines__secondary {
  background: white;
  grid-area: secondary;
}

.c-headlines__tertiary {
  background: white;
  grid-area: tertiary;
}

.c-headlines__list {
  grid-area: list;
}



.c-headlines-list {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
