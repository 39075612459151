.c-AdLeaderboard {
  display: flex;
  justify-content: center;
  min-height: 50px;
  margin: .5rem auto;
  max-width: 320px;

  @include mq($from: 970px) {
    max-width: 1000px;
    min-height: 90px;
  }
}
