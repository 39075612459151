.o-Content {
  .o-container {
    padding: 9px;
  }

  > .o-Content-media {
    .c-EntryFigure-caption {
      padding: .279em 1em;
    }
  }

  .c-EntryByline {
    border-bottom: 1px solid $grey-lighter;
    border-top: 1px solid $grey-lighter;
    padding: 9px 0;
  }
}

.o-Content-hero {
  margin-left: auto;
  margin-right: auto;
  max-width: $global-site-width;
}

.o-Content-body {
  .o-Content-media {
    display: none;
  }
}

.o-Content-main {
  position: relative;
}

.o-Content-aside {
  border-top: 1px solid $grey-lighter;
  margin-top: $global-spacing-unit;
  padding-top: $global-spacing-unit;
}

.o-Content-header,
.o-Content-body {
  padding: 3%;
}

.o-Content-header {
  border-bottom: 1px solid $grey-lighter;
}

.o-Content-media,
.o-Content-meta {
  margin-bottom: $global-spacing-unit;
}

@include mq($from: md) {
  .o-Content {
    .o-container {
      padding: 1em;
    }
  }

  .o-Content {
    > .o-Content-media {
      display: none;
    }
  }

  .o-Content--hasHero .o-Content-header {
    display: none;
  }

  .o-Content-body {
    .o-Content-media {
      display: block;
    }
  }

  .o-Content-meta {
    float: left;
    width: 200px;

    + .o-Content-text {
      margin-left: calc(200px + #{$global-spacing-unit});
    }
  }
}

@include mq($from: lg) {
  .o-ContentBody {
    .o-Content-media,
    .c-EntryText {
      margin-left: calc(200px + #{$global-spacing-unit});
    }

    .o-Content-meta {
      left: 0;
      position: absolute;
      top: 0;
      width: 200px;
    }
  }
}

@include mq($from: 68em) {
  .o-Content {
    .o-container {
      display: flex;
    }
  }

  .o-Content-main {
    flex: 1;
  }

  .o-Content-aside {
    border-left: 1px solid $grey-lighter;
    border-top: none;
    margin: 0 0 0 $global-spacing-unit;
    padding: 0 0 0 $global-spacing-unit;
    width: calc(300px + #{$global-spacing-unit};
  }
}

/*.o-Content {
  display: grid;
  grid-row-gap: 1em;
  grid-template-areas:
  "margin media margin"
  "head   head  head"
  "margin meta margin"
  "margin body margin"
  "margin side margin";
  grid-template-columns: [full-start] minmax(9px, 1fr)
    [main-start] minmax(0, 40em) [main-end]
    minmax(9px, 1fr) [full-end];
  grid-template-rows: repeat(4, max-content) 1fr;

  .o-ContentHead {
    grid-area: header;
    grid-column: 1 / 4;
    grid-row: 2 / 3;
  }

  .o-ContentBody {
    grid-area: body;
    grid-column: 1 / 4;
    grid-row: 3 / 6;
  }
}

.o-Content-media {
  grid-column: 1/4;
  grid-row: 1/2;
}

.o-Content-meta {
  grid-column: 2/3;
  grid-row: 3/4;
}

.o-Content-body {
  grid-column: 2/3;
  grid-row: 4/5;
}

.o-Content-sidebar {
  grid-column: 2/3;
  grid-row: 5/6;
}

.o-ContentHead {
  display: grid;
  grid-template-areas:
  "margin header margin";
  grid-template-columns: [full-start] minmax(9px, 1fr)
    [main-start] minmax(0, #{$global-site-width}) [main-end] minmax(9px, 1fr) [full-end];

  .o-ContentHead-header {
    grid-column: 2 / 3;
    padding: 1em 0;
  }

  &.is-dark {
    background: $indigo;
    color: #fff;
  }
}

@include mq($from:sm) {
  .o-Content {
    grid-template-areas:
    "head   head  head"
    "margin meta  margin"
    "margin media margin"
    "margin body  margin"
    "margin side  margin";
    grid-column-gap: $global-spacing-unit;

    .o-ContentHead {
      grid-row: 1/2;
      grid-column-gap: $global-spacing-unit;
      padding-top: 1em;
    }
  }

  .o-Content-media {
    grid-row: 2/3;
  }

  .o-Content-meta {
    grid-row: 3/4;
  }
}

@include mq($from: 40em) {
  .o-Content-media {
    grid-column: 2/3;
  }
}

@include mq($from: 50em) {
  .o-Content {
    grid-template-areas:
    "head   head  head head"
    "margin media side margin"
    "margin meta  side margin"
    "margin body  side margin";
    grid-template-columns: [full-start] minmax(9px, 1fr)
      [main-start] minmax(0, calc(#{$global-site-width} - 300px)) [side-start] 300px  [main-end]
      minmax(9px, 1fr) [full-end];
    grid-template-rows: repeat(3, max-content) 1fr;

    .o-ContentHead {
      grid-column: 1 / 5;
      grid-template-areas:
      "margin header side margin";
    }

    .o-ContentBody {
      grid-column: 1 / 5;
    }
  }

  .o-Content-sidebar {
    grid-column: 3 / 4;
    grid-row: 2 / 5;
  }

  .o-ContentHead {
    grid-template-columns: [full-start] minmax(#{$global-spacing-unit}, 1fr)
      [main-start] minmax(0, calc(#{$global-site-width} - 300px)) [side-start] 300px  [main-end]
      minmax(#{$global-spacing-unit}, 1fr) [full-end];
  }
}

@include mq($from: 70em) {
  .o-Content {
    grid-template-areas:
    "head   head head  head head"
    "margin meta media side margin"
    "margin meta body  side margin";
    grid-template-columns: [full-start] minmax(9px, 1fr)
      [main-start] 200px [meta-end] minmax(0, calc(#{$global-site-width} - 250px - 300px)) [side-start] 300px  [main-end] minmax(9px, 1fr) [full-end];
    grid-template-rows: max-content max-content 1fr;

    .o-ContentHead {
      grid-column: 1 / 6;
    }

    .o-ContentBody {
      grid-column: 1 / 6;
      grid-row: 2 / 4;
    }
  }

  .o-Content-media {
    grid-column: 3 / 4;
  }

  .o-Content-meta {
    grid-column: 2 / 3;
    grid-row: 2 / 4;
  }

  .o-Content-sidebar {
    grid-column: 4 / 5;
    grid-row: 2 / 4;
  }

  .o-Content-body {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
  }
}

/*
| media |
| title |
| meta  |
| main  |
| side  |



| margin | title    title  |       | margin |
| margin | meta  |  media  | sideB | margin |
| margin |       |  body   | sideB | margin |
*
**/
