/* ==========================================================================
   #BLOCK
   ========================================================================== */

/**
 * Stacked image-with-text object. A simple abstraction to cover a very commonly
 * occurring design pattern.
 */

.o-Block {
  display: block;
  margin-bottom: $global-spacing-unit;
  text-align: left;
}


  .o-Block-figure {
    display: block;
    margin-bottom: $global-spacing-unit-xs;


    /* Size variants.
       ====================================================================== */

    .o-Block--flush > & {
      margin-bottom: 0;
    }

    .o-Block--xs > & {
      margin-bottom: $global-spacing-unit-xs;
    }

    .o-Block--sm > & {
      margin-bottom: $global-spacing-unit-sm;
    }

    .o-Block--lg > & {
      margin-bottom: $global-spacing-unit-lg;
    }

    .o-Block--xl > & {
      margin-bottom: $global-spacing-unit-xl;
    }

  }


  .o-Block-body {
    display: block;
  }

  .o-Block-inner {
    padding: $global-spacing-unit-xs $global-spacing-unit-sm;
  }





/* Alignment variants.
   ========================================================================== */

.o-Block--right {
  text-align: right;
}

.o-Block--center {
  text-align: center;
}
