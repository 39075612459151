$max-dialog-width: 960px;
$nav-item-width: 44px;

.c-gallery {
  background: rgba($indigo, .75);
  bottom: 0;
  display: none;
  flex-direction: column;
  left: 0;
  padding: 0 8px 44px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

.c-gallery:target { display: flex; }

.c-gallery__close {
  color: #fff;
  font-size: 2.074em;
  font-weight: 700;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  width: 44px;
}

.c-gallery__nav {
  background: #fff;
  bottom: 0;
  display: flex;
  height: 44px;
  left: 0;
  position: absolute;
  right: 0;
}

.c-gallery__nav-item {
  color: $indigo;
  flex: 1;
  line-height: 44px;
  text-align: center;
  text-decoration: none;
}

.c-gallery__dialog {
  background: #fff;
  margin: 0 auto;
  max-width: $max-dialog-width;
}

.c-gallery__stage {
  background: $grey-lighter;
  position: relative;
}

.c-gallery__figure {
  object-fit: contain;
}

.c-gallery__count {
  border-bottom: 1px solid $grey-lighter;
  font-size: .833em;
  padding: .402rem 1rem;
  text-align: center;
}

.c-gallery__body {
  overflow-y: auto;
}

.c-gallery__title {
  color: #fff;
  font-family: $heading-font-family;
  font-size: 1.2em;
  margin: 0 auto;
  max-width: $max-dialog-width;
  padding: 1rem 44px 1rem 0;
  width: 100%;
}

@include mq($from: md) {
  .c-gallery__title {
    font-size: 1.728em;
  }

  .c-gallery__dialog {
    display: flex;
  }

  .c-gallery__stage {
    flex: 1;
  }

  .c-gallery__figure {
    height: 100%;
    width: 100%;
  }

  .c-gallery__body {
    width: 250px;
  }
}

@include mq($from: lg) {
  .c-gallery {
    padding-left: $nav-item-width;
    padding-right: $nav-item-width;
  }

  .c-gallery__nav {
    background: transparent;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(#{$max-dialog-width} + #{$nav-item-width} + #{$nav-item-width}); /* dialog width plus nav item widths */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .c-gallery__nav-item {
    background: transparent;
    color: #fff;
    overflow: hidden;
    position: absolute;
    width: 44px;
    z-index: 1;
  }

  .c-gallery__prev {
    left: 0;
  }

  .c-gallery__next {
    right: 0;
  }
}
