.o-List {
  display: block;
  margin: 0;
  padding: 0;
}

.o-List-item {
  margin-bottom: .335em;
}

.o-List-entry {
  border-top: 1px solid $grey-lighter;
  margin-bottom: .482em;
}

.o-List--withImage {
  > .o-List-item {
    padding-left: 1.44em;
    position: relative;

    > .o-List-image {
      left: 0;
      position: absolute;
      top: 3px;
      width: 1em;
    }
  }
}
