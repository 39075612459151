.u-absolute { position: absolute; }
.u-relative { position: relative; }
.u-fixed { position: fixed; }
.u-sticky { position: sticky; }

.u-pin-t,
.u-top-0 { top: 0; }
.u-pin-r,
.u-right-0 { right: 0; }
.u-bottom-0 { bottom: 0; }
.u-left-0 { left: 0; }

.u-inset-0 { top: 0; right: 0; bottom: 0; left: 0; }
.u-inset-y-0 { top: 0; bottom: 0; }

.u-top-full { top: 100%; }

.u-z-10 { z-index: 10; }

@include mq($from: xs) {
  .xs\:u-absolute { position: absolute; }
}

@include mq($from: md) {
  .md\:u-absolute { position: absolute; }
}

@include mq($from: xl) {
  .xl\:u-absolute { position: absolute; }
}
