.c-Search-header {
  background: $indigo;
  color: #fff;
  overflow: hidden;
  padding: 0;
  @include clearfix;

  a {
    color: #fff;
  }

  .c-Form {
    font-size: 1.44em;
    margin: 0;
  }

  .c-Form-label {
    fill: currentColor;
  }

  .c-Form-control {
    background: transparent;
    border: none;
    border-bottom: 2px solid $grey-light;
    color: $yellow;
    padding-left: $global-spacing-unit;
    padding-right: $global-spacing-unit;

    &:focus {
      color: #fff;
    }
  }

  .c-Search-title,
  .c-Form {
    position: relative;
    z-index: 1;
  }
}

.c-Search-main {
  @include clearfix;
}

.c-Search-options {
  padding: 0;
}

.c-Search-results {
  background: rgba($global-body-background, .15);
}

.c-Search-title {
  font-size: 2.074em;
  font-weight: 600;
  line-height: 1.2;
  margin: 2.074rem $global-spacing-unit;
}

$search-options-width: 40%;
@include mq($from: sm) {
  .c-Search-options {
    float: left;
    min-width: $search-options-width;

    .c-Toggle-label {
      display: none;
    }

    .c-Toggle-group {
      display: block;
    }
  }

  .c-Search-results,
  .c-Search-pagination {
    margin-left: $search-options-width;
  }
}

@include mq($from: lg) {
  /* combine the height of two listing components and the top margin
     of the search title */
  $search-header-height: calc(226px + 2.074rem);


  .c-Search-header {
    /* combine height of two listing components and top padding of the top
       listing. */
    margin-bottom: calc(-226px);
    min-height: $search-header-height;
    padding-bottom: 1rem;

    .c-Search-title,
    .c-Form {
      margin-left: 0;
      margin-right: 0;
      padding: 0 $global-spacing-unit;
      width: $search-options-width;
    }

    .c-Search-title {
      font-size: 2.488em;
    }
  }

  .c-Search-options {
    padding-top: 226px;
  }
}
