.o-Box {
  display: block;
  padding: $global-spacing-unit;
  @include clearfix;

  > :last-child {
    margin-bottom: 0;
  }
}

@include mq($from:xl) {
  .o-Box--xl {
    padding: $global-spacing-unit-xl;
  }
}
