/**
 *
 * <div class="c-cover-image">
 *   <img src="" class="c-cover-image__figure">
 * </div>
 */

.c-cover-image {
  position: relative;
}

.c-cover-image__figure {
  height: 100vh;
  max-height: 700px;
  object-fit: cover;
  width: 100%;
}

.c-cover-image::before {
  background: linear-gradient(transparent, rgba($global-body-background, 1));
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
