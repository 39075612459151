.c-ProfileCard {
  background: #fff;
  box-shadow: $global-feather-shadow;
}

.c-ProfileCard-figure,
.c-ProfileCard-body {
  padding: $global-spacing-unit;
}

.c-ProfileCard-figure {
  align-items: center;
  background: $indigo;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;

  img {
    position: relative;
  }
}

.c-ProfileCard-body {
  border: 1px solid $grey-light;
  flex: 1;
}

.c-ProfileCard-background {
  height: 100%;
  left: 0;
  opacity: .05;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@supports (filter: blur()) {
  .c-ProfileCard-background {
    filter: blur(30px);
    opacity: 1;
  }
}

@include mq($from: lg) {
  .c-ProfileCard {
    display: flex;
  }

  .c-ProfileCard-figure {
    width: 250px;
  }
}
