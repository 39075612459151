.c-layout {
  display: grid;
  grid-gap: 1rem;
}

.c-layout--two-column-sidebar {
  grid-template-areas:
  "main"
  "sidebar";
  grid-template-columns: minmax(auto, 768px);
  justify-content: center;

  @include mq($from: lg) {
    grid-template-areas: "main sidebar";
    grid-template-columns: [main] 1fr [sidebar] 300px;
  }
}

.c-layout__main {
  grid-area: main;
}

.c-layout__sidebar {
  grid-area: sidebar;
}
