.u-colorMuted {
  color: $grey !important;
}

.u-colorBase {
  color: $global-text-color !important;
}

.u-colorSale {
  color: red !important;
}

.u-colorPrice {
  color: $green;
}

.u-colorWhite {
  color: #fff !important;
}
