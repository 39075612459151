/* ==========================================================================
   #Media
   ========================================================================== */

/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */

.o-media {
  display: flex;
  flex-wrap: wrap;
}

.o-media__figure {
  margin-right: $global-spacing-unit-sm;

  &:last-child {
    margin-left: 1em;
    margin-right: 0;
  }
}

.o-media__body {
  flex: 1;

  &,
  > :last-child {
     margin-bottom: 0;
  }
}





/* Size variants.
   ========================================================================== */

/**
 * Modify the amount of space between our image and our text. We also have
 * reversible options for all available sizes.
 */


/* Gutterless media objects.
  ========================================================================== */

.o-media--flush {

  > .o-media__figure {
    margin-right: 0;
    margin-left: 0;
 }

}


.o-media--tiny {

  > .o-media__figure {
    margin-right: $global-spacing-unit-xs;
  }

  &.o-media--reverse {

    > .o-media__figure {
      margin-right: 0;
      margin-left: $global-spacing-unit-xs;
    }

  }

}


.o-media--small {

  > .o-media__figure {
    margin-right: $global-spacing-unit-sm;
  }

  &.o-media--reverse {

    > .o-media__figure {
      margin-right: 0;
      margin-left: $global-spacing-unit-sm;
    }

  }

}


.o-media--large {

  > .o-media__figure {
    margin-right: $global-spacing-unit-lg;
  }

  &.o-media--reverse {

    > .o-media__figure {
      margin-right: 0;
      margin-left: $global-spacing-unit-lg;
    }

  }

}


.o-media--huge {

  > .o-media__figure {
    margin-right: $global-spacing-unit-xl;
  }

  &.o-media--reverse {

    > .o-media__figure {
      margin-right: 0;
      margin-left: $global-spacing-unit-xl;
    }

  }

}



/* Reversed media objects.
   ========================================================================== */

.o-media--reverse {
  grid-template-areas: "title image"
                       "body image";
}


/* Alignment modifications.
   ========================================================================== */
.o-media--middle {
  align-items: center;
}

.o-media--end {
  align-items: flex-end;
}
