summary {
  cursor: pointer;
  display: block;
}

summary::-webkit-details-marker {
 display: none;
}

summary::before {
  content: '\00d7';
  display: inline-block;
  height: 1em;
  line-height: 1;
  margin-right: 0.279em;
  text-align: center;
  transform: rotate(45deg);
  transition: transform .1s linear;
  width: 1em;
}

details[open] > summary::before {
  transform: rotate(90deg);
}
