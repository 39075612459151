/**
 * Adds a skinny line to the bottom of an element to give it a timeline look.
 *
 * MUST be chained with a flex layout class.
 *
 * Example: <div class="u-flex u-flex-col c-timeline-slat">
 */

.c-timeline-slat::after {
  background: $grey;
  content: '';
  flex: 1;
  margin: 0 auto;
  min-height: 1em;
  width: 3px;
}
