/** @define Button */

/**
 * The button classes are best applied to links and buttons.
 * These components can be used in forms, as calls to action, or as part of the
 * general UI of the site/app.
 */

/**
 * 2. Inherit font styles from ancestor.
 * 3. Prevent button text from being selectable.
 */

.c-button {
  background: none;
  border-color: transparent;
  border-radius: .25rem;
  border-style: solid;
  border-width: 1px;
  color: inherit;
  display: inline-block;
  font: inherit; /* 2 */
  margin: 0;
  padding: .5rem 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  user-select: none; /* 3 */
}

/**
 * Remove excess padding and border in Firefox 4+
 */

.c-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 */

.c-button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

/**
 * UI states
 */

.c-button:hover,
.c-button:focus,
.c-button:active {
  text-decoration: none;
}

.c-button:disabled,
.c-button.is-disabled {
  cursor: default;
  opacity: 0.6;
}

.c-button:active {
  transform: scale(.95);
}

/* Modifiers
   ========================================================================== */

/* Primary */
.c-button--primary {
  background: $blue-500;
  color: #fff;
  font-weight: 600;
}

/* Call to action */
.c-button--cta {
  background: linear-gradient(to right, $blue-green, $blue);
  box-shadow: $global-soft-shadow;
  color: #fff;
}

.c-button--outline {
  border-color: currentColor;
}

/* Full width */
.c-button--full {
  display: block;
}
