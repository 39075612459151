.c-TextGroup,
.c-TextGroup-title {
  line-height: 1.2;
}

.c-TextGroup-kicker {
  color: $grey-dark;
  font-size: .833em;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;

  a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      @include skip-ink;
    }
  }
}

.c-TextGroup-title {
  display: block;
  margin-bottom: .194em;

  a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      @include skip-ink;
    }
  }
}

.c-TextGroup-dek {
  color: $grey;
  display: block;
  font-size: .833em;
  margin-bottom: 0;
}
