.o-prose {
  > p {
    margin-bottom: $global-spacing-unit;
  }

  > ul {
    padding-left: 1rem;

    > li {
      margin-bottom: .5rem;

      &::before {
        color: $blue;
        content: '\2022';
        display: inline-block;
        margin-left: -1rem;
        width: 1rem;
      }
    }
  }
}
