.c-form__group {
  position: relative;
}

.c-form__control {
  appearance: none;
  line-height: 1.25;
  width: 100%;
}

.c-form__label {
  svg {
    display: block;
  }
}

.c-form__help {
  background: $grey-lighter;
  color: $grey-dark;
  font-size: .694em;
  text-transform: uppercase;
}

.c-form--float-label {
  .c-form__group {
    &:focus-within {
      .c-form__label {
        color: blue;
      }
    }
  }

  .c-form__control {
    height: 3.5rem;
    transition: padding 250ms ease;

    > select {
      background: none;
    }

    & + .c-form__label {
      color: $gray-500;
      display: none;
      font-size: .875rem;
      opacity: 0;
      position: absolute;
      top: 10px;
      transition: top 0.7s ease, opacity 0.7s ease;
    }

    &:not(:placeholder-shown),
    option:checked {
      padding-top: 1.25rem;

      + .c-form__label {
        display: block;
        opacity: 1;
        top: .25rem;
      }
    }

    &:focus + .c-form__label {
      color: $gray-800;
    }
  }
}

textarea {
  transition: height .3s ease;
}

textarea.c-form__control:not(:placeholder-shown) {
  height: 5em;
}

.c-form--search {
  .c-form__group {
    margin: 0;
  }

  .c-form__label {
    height: 1em;
    left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1em;
  }

  .c-form__control {
    text-indent: calc(10px + 1em)
  }
}

.c-form__label--no-float {
  opacity: 0;
}

.c-form__footer {
  padding-bottom: $global-spacing-unit;
  padding-top: $global-spacing-unit;

  .o-Box > & {
    margin-left: -$global-spacing-unit;
    margin-right: -$global-spacing-unit;
    padding: $global-spacing-unit;
  }
}
