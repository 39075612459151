///* ========================================================================
//   #CORE
//   ======================================================================== */

// This core file sets up inuitcss’ most important setup variables. They
// underpin a lot of how the framework functions and should be modified and
// preconfigured with caution.


// Base typographical styles and baseline grid. You need to define these values
// in pixels: inuitcss will convert them to more appropriate units.

$global-font-size:    16px !default;
$global-line-height:  23.04px !default;





// Spacing values are determined based on your project’s global line height (i.e
// your baseline grid). It is not recommended that you modify these following
// variables (it can break your vertical rhythm), but if you need to, you can.

$global-spacing-unit: ($global-font-size / $global-font-size) * 1rem !default;


// How many times larger/smaller than the default should our spacing unit
// variants be?

$global-spacing-unit-factor-xs:   0.335 !default;
$global-spacing-unit-factor-sm:  0.694 !default;
$global-spacing-unit-factor-lg:  2.074 !default;
$global-spacing-unit-factor-xl:   4.3   !default;





// Check that the chosen font rules are pixel numbers.

@each $_font-globals in
      $global-font-size
      $global-line-height {

  @if (type-of($_font-globals) == number) {

    @if (unit($_font-globals) != "px") {
      @error "`#{$_font-globals}` needs to be a pixel value.";
    }

  } @else {
    @error "`#{$_font-globals}` needs to be a number.";
  }

}


// Check that the chosen size factors are unitless numbers.

@each $_spacing-unit in
      $global-spacing-unit-factor-xs
      $global-spacing-unit-factor-sm
      $global-spacing-unit-factor-lg
      $global-spacing-unit-factor-xl {

  @if (type-of($_spacing-unit) == number) {

    @if (unitless($_spacing-unit) == false) {
      @error "`#{$_spacing-unit}` needs to be unitless.";
    }

  } @else {
    @error "`#{$_spacing-unit}` needs to be a number.";
  }

}


// Private/framework-only reassignment. Do not alter anything below.

$global-spacing-unit-xs: $global-spacing-unit * $global-spacing-unit-factor-xs;
$global-spacing-unit-sm: $global-spacing-unit * $global-spacing-unit-factor-sm;
$global-spacing-unit-lg: $global-spacing-unit * $global-spacing-unit-factor-lg;
$global-spacing-unit-xl: $global-spacing-unit * $global-spacing-unit-factor-xl;
