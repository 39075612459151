.c-homepage-grid {
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "headlines"
    "onlocation"
    "events"
    "industry"
    "promo";
}

.c-homepage-grid__headlines { grid-area: headlines; }
.c-homepage-grid__onlocation { grid-area: onlocation; }
.c-homepage-grid__industry { grid-area: industry; }
.c-homepage-grid__events { grid-area: events; }
.c-homepage-grid__promo {
  grid-area: promo;
}

@include mq($from: xs) {
  .c-homepage-grid__promo {
    display: grid;
    grid-template-columns: repeat(2, minmax(240px, 1fr));
  }
}

@include mq($from: sm) {
  .c-homepage-grid {
    grid-template-columns: 50% 50%;
    grid-template-areas:
      "headlines headlines"
      "onlocation events"
      "industry industry"
      "promo promo";
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .c-homepage-grid,
  .c-homepage-grid__promo {
    gap: 1rem;
  }
}

@include mq($from: md) {
  .c-homepage-grid { margin-bottom: 2rem; margin-top: 2rem; }
  .c-homepage-grid,
  .c-homepage-grid__promo {
    gap: 2rem;
  }
}

@include mq($from: lg) {
  .c-homepage-grid {
    grid-template-columns: 1fr minmax(300px, 25%);
    grid-template-areas:
      "headlines onlocation"
      "industry events"
      "promo promo";
  }
}

@include mq($from: xl) {
  .c-homepage-grid {
    grid-template-columns: 1fr 1fr minmax(300px, 25%);
    grid-template-areas:
      "headlines headlines onlocation"
      "industry industry events"
      "promo promo promo";
  }
}
