.c-SpacerTitle {
  color: $global-header-color;
  font-family: $display-font-family;
  margin-bottom: .402em;
  position: relative;

  &::after {
    border-top: 3px solid currentColor;
    content: '';
    display: block;
    height: 0;
    margin-top: 4px;
    width: 8px;
  }
}
