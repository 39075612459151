.c-dropdown {
  position: relative;
}

.c-dropdown__toggle {
  &::after {
    border-top: .25em solid currentColor;
    border-left: .25em solid transparent;
    border-right: .25em solid transparent;
    content: '';
    display: inline-block;
    margin-left: .25em;
    vertical-align: middle;
  }
}

.c-dropdown__menu {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.c-dropdown.is-open {
  .c-dropdown__menu {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
