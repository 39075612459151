.u-sidebar {
  width: 100%;
}

@include mq($from: lg) {
  .u-sidebar {
    width: calc(300px + #{$global-spacing-unit} + #{$global-spacing-unit});
  }
}

/* Z-Index
   ========================================================================== */
.u-z-10 { z-index: 10; }
