.c-list-style {
  list-style-type: none;

  li {
    margin-left: 1.2em;
  }

  a {
    color: inherit;
  }
}

.c-list-style--icon {
  li {
    align-items: flex-start;
    display: flex;
  }

  span {
    display: block;
    margin-left: -1.2em;
    width: 1.2em;
  }
}

.c-list-style--square {
  list-style-type: square;
}

.c-ListGroup {

}

.c-ListGroup-item {
  background: darken($grey-lightest, 4%);
  border-top: 1px solid $grey-lightest;
  padding: $global-spacing-unit-sm $global-spacing-unit;
  position: relative;

  &:first-child {
    border-top: none;
  }
}
