.c-Pagination {
  display: flex;
  padding: $global-spacing-unit 0;
}

.c-Pagination-action {
  border: 1px solid transparent;
  border-radius: 20px;
  color: $grey;
  display: block;
  line-height: 2;
  padding: 0 .279em;
  text-align: center;
  text-decoration: none;
  width: 2em;

  .is-active > & {
    border-color: currentColor;
  }
}
