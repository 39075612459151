.c-PageLayout {
  display: grid;
  grid-template-columns: [main-start] minmax(40em, 1fr) [main-end] minmax(300px,400px) [sidebar-end];
  grid-gap: $global-spacing-unit;
}

.c-PageLayout-main {
  grid-column: main-start / main-end
}

.c-PageLayout-sidebar {
  grid-column: main-end / sidebar-end;
}
