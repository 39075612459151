/*
 *  Owl Carousel - Core
 */
.c-Carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.c-Carousel-stage {
  display: flex;
	position: relative;
	-ms-touch-action: pan-Y;
	-moz-backface-visibility: hidden; /* fix firefox animation glitch */
}

.c-Carousel-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.c-Carousel-stageOuter {
	position: relative;
	overflow: hidden;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}

.c-Carousel-wrapper,
.c-Carousel-item{
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
  transform: translate3d(0,0,0);
}

.c-Carousel-item {
	margin-bottom: 0;
	position: relative;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.c-Carousel-nav.disabled,
.c-Carousel-dots.disabled {
	display: none;
}

.c-Carousel-nav .c-Carousel-prev,
.c-Carousel-nav .c-Carousel-next,
.c-Carousel-dot {
	cursor: pointer;
	cursor: hand;
	user-select: none;
}

.c-Carousel--loaded {
	display: block;
}

.c-Carousel--loading {
	opacity: 0;
	display: block;
}

.c-Carousel--hidden {
	opacity: 0;
}

.c-Carousel--refresh .c-Carousel-item {
	visibility: hidden;
}

.c-Carousel--drag .c-Carousel-item {
	cursor: grab;
	user-select: none;
}

.c-Carousel--grab {
	cursor: move;
	cursor: grab;
}

.c-Carousel--rtl {
	direction: rtl;
}

.c-Carousel--rtl .c-Carousel-item {
	float: right;
}

/* No Js */
.no-js .c-Carousel {
	display: block;
}

/* =============================================================================
   Themes
	 ========================================================================== */
.c-Carousel {

}

.c-Carousel-item {
	img {
		left: 50%;
		max-height: 100%;
		position: relative;
		transform: translateX(-50%);
	}
}

.c-Carousel-nav {
	border: 1px solid $grey-lighter;
	box-shadow: $global-feather-shadow;
	display: flex;
}

.c-Carousel-prev,
.c-Carousel-next {
	background: #fff url('/assets/img/icon-arrow-left.svg') no-repeat center center;
	background-size: 20px auto;
  display: block;
	height: 2.986rem;
	overflow: hidden;
	padding: .335rem;
	text-indent: 2.986rem;
	width: 2.986rem;

  &.disabled {
		cursor: default;
		opacity: .25;
	}
}

.c-Carousel-next {
	background-image: url('/assets/img/icon-arrow-right.svg');
}

/* Gallery
   ========================================================================== */
#c-Gallery {
  .c-Carousel-nav {
		bottom: 1em;
		left: 1em;
		position: absolute;
	}
}
