.c-gallery-cover {
  display: block;
  position: relative;
  z-index: 2;

  &::before,
  &::after {
    background: $grey-dark;
    border: 1px solid $global-body-background;
    bottom: -6px;
    box-shadow: 0 4px 8px rgba(0,0,0,.1);
    content: '';
    left: 6px;
    position: absolute;
    right: 6px;
    top: 0;
    z-index: 1;
  }

  &::before {
    background: $grey;
    bottom: -12px;
    left: 12px;
    right: 12px;
  }
}

.c-gallery-cover__figure {
  box-shadow: 0 4px 8px rgba(0,0,0,.15);
  position: relative;
  z-index: 3;

  .o-flex-embed__content {
    background: linear-gradient(rgba($indigo,0), rgba($indigo, .8));
  }
}
