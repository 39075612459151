.c-Faceting-attribute {
  padding-bottom: .335em;
}
.c-Faceting-control {
  display: none;
}

.c-Faceting-label {
  color: $grey-dark;
  text-transform: uppercase;
  padding-left: 1em;
  position: relative;

  &::before {
    background: $grey-light;
    border-radius: .579em;
    content: '';
    height: .579em;
    left: 0;
    position: absolute;
    top: .402em;
    width: .579em;

  }

  :checked + & {
    color: $grey-darker;
    font-weight: 600;

    &::before {
      background: currentColor;
    }
  }

  &:hover {
    color: $grey;

    &::before {
      background: $grey-dark;
    }
  }
}

.c-Faceting-group {
  padding: $global-spacing-unit;
}

.c-Toggle {
  display: none;

  &:checked + .c-Toggle-label + .c-Toggle-group {
    display: block;
  }
}

.c-Toggle-label {
  background: $blue;
  color: #fff;
  display: block;
  padding: 1em;
  text-align: center;
  text-transform: uppercase;

  &::before {
    content: '+ ';
  }

  .c-Toggle:checked + &::before {
    content: '- ';
  }
}

.c-Toggle-group {
  display: none;
}

@include mq($from: lg) {
  .c-Faceting-list {
    columns: 2;
  }
}
