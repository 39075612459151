.c-Badge {
  background: $grey-light;
  display: inline-block;
  font-size: .833em;
  font-weight: $global-bold-weight;
  line-height: 1;
  padding: .279em;
}

.c-Badge--pro {
  background: $pro-color;
  color: $indigo;
  text-transform: uppercase;
}
