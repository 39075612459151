/* =============================================================================
   Link utilities
   ========================================================================== */

/**
 * Clean link
 *
 * A link without any text-decoration
 */

.u-link-clean,
.u-link-clean:hover,
.u-link-clean:focus,
.u-link-clean:active {
  color: inherit !important;
  text-decoration: none !important;
}

/**
 * Link complex
 *
 * A common pattern is to have a link with several pieces of text and/or an
 * icon, where only one piece of text should display the underline when the
 * link is the subject of user interaction.
 *
 * Example HTML:
 *
 * <a class="u-link-complex" href="#">
 *   Link complex
 *   <span class="u-link-complex__target">target</span>
 * </a>
 */

.u-link-complex,
.u-link-complex:hover,
.u-link-complex:focus,
.u-link-complex:active {
  color: inherit;
  text-decoration: none;
}

.u-link-complex:hover .u-link-complex__target,
.u-link-complex:focus .u-link-complex__target,
.u-link-complex:active .u-link-complex__target {
  @include skip-ink;
}

/**
 * Block-level link
 *
 * Combination of traits commonly used in vertical navigation lists.
 */

.u-link-block,
.u-link-block:hover,
.u-link-block:focus,
.u-link-block:active {
  display: block;
  text-decoration: none;
}
