.c-DirectoryHeader {
  background: $primary-brand-color;
  color: #fff;
  margin-bottom: 2.074em;

  a {
    color: #fff;
  }

  .c-Form {
    font-size: 1.44em;
    margin: 0 -#{$global-spacing-unit};
  }

  .c-Form-label {
    fill: currentColor;
  }

  .c-Form-control {
    background: $indigo;
    border: none;
    color: $yellow;
    padding-left: $global-spacing-unit;
    padding-right: $global-spacing-unit;

    &:focus {
      color: #fff;
    }
  }
}

.c-DirectoryHeader-content {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 0 $global-spacing-unit 0;
}

.c-DirectoryHeader-title {
  font-size: 2.074em;
  line-height: 1.2;
  margin: 1em 0;
  text-transform: uppercase;
}

@include mq($from: md) {
  .c-DirectoryHeader {
    .c-Form {
      margin-bottom: 1em;
    }
  }

  .c-DirectoryHeader-content {
    width: 50%;
  }
}

@include mq($from: lg) {
  .c-DirectoryHeader-content {
    width: 40%;
  }

  .c-DirectoryHeader-title {
    font-size: 2.482em;
    width: 80%;
  }
}
