.c-DirectoryCategory {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  position:  relative;
  text-align: center;
}

.c-DirectoryCategory-action {
  background: #fff;
  box-shadow: 0 1px 2px rgba(0,0,0,.1);
  display: flex;
  flex-flow: column wrap;
  height: 175px;
  justify-content: center;
  padding: $global-spacing-unit-lg $global-spacing-unit;
  text-decoration: none;
  width: 100%;
}
.c-DirectoryCategory-title {
  color: $global-text-color;
  font-weight: $global-bold-weight;
  display: block;
}

.c-DirectoryCategory-subtitle {
  color: $grey;
}
