/* ==========================================================================
   #TABLES
   ========================================================================== */

/**
 * 1. Ensure tables fill up as much space as possible.
 */

table {
  width: 100%; /* [1] */
}

th {
  text-align: left;
}

td, th {
  padding: .279em;
}

tr:first-child th,
tr:first-child td {
  border-top: none;
}

tbody {
  th {
    vertical-align: top;
  }
}
