.c-EntryGroup {
  padding: 9px 0;

  a {
    text-decoration: none;
  }
}

.c-EntryGroup-image {
  margin-bottom: 1em;
}

.c-EntryGroup-body {

}

.c-EntryGroup-category {
  font-size: .833em;
  margin-bottom: 1em;
}

.c-EntryGroup-title {
  color: $global-header-color;
  font-size: 1.2em;
  font-weight: 400;
}

@include mq($from: sm) {
  .c-EntryGroup {
    display: flex;
    padding: 0;
  }

  .c-EntryGroup-image {
    margin: 0;
    width: 30%;
  }

  .c-EntryGroup-body {
    flex: 1;
    padding: $global-spacing-unit-sm;
  }
}

@include mq($from: md) {
  .c-EntryGroup-title {
    font-size: 1.44em;
  }

  .c-EntryGroup-image {
    margin: 0;
  }

  .c-EntryGroup-body {
    padding: $global-spacing-unit;
  }
}
