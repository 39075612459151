.c-FeatureHeader {
  background: linear-gradient(to bottom, $green, $blue);
  margin: 0 0 $global-spacing-unit;
  overflow: hidden;
  padding: 2.986em 1.2em;
  position: relative;

  &::after,
  &::before {
    background: rgba(255,255,255,.05);
    content: '';
    display: block;
    height: 100px;
    left: 0;
    position: absolute;
    top: calc(50% - 50px);
    transform: perspective(15px) rotateY(-1deg) translateY(-5%);
    transform-origin: center left;
    width: 100%;
    z-index: 0;
  }

  &::before {
    transform: perspective(15px) rotateY(-1deg) translateY(-20%);
    transform-origin: top left;
  }
}

.c-FeatureHeader-text,
.c-FeatureHeader-figure {
  position: relative;
  transform: translate3d(0,0,0); /* bug fix in webkit to force z-index positioning */
  z-index: 1;
}

.c-FeatureHeader-text {

  p {
    color: $deep-indigo;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  h2 {
    color: #fff;
    font-family: $serif-font-family;
    font-size: 2.986em;
    font-weight: 400;
    line-height: 1;
  }

  h3 {
    color: #fff;
    font-size: 1em;
    font-weight: 400;
  }
}

.c-FeatureHeader-figure {
  margin-bottom: -2.986em;
}

@include mq($from:sm) {
  .c-FeatureHeader {
    background: linear-gradient(to right, $green, $blue);
  }

  .c-FeatureHeader-text {
    width: 70%;

    h2 {
      font-size: 3.583em;
    }

    h3 {
      font-size: 1.2em;
    }
  }

  .c-FeatureHeader-figure {
    bottom: 0;
    left: 72%;
    margin: 0;
    max-height: 90%;
    max-width: 499px;
    position: absolute;
    width: 400px;
  }
}

@include mq($from:md) {
  .c-FeatureHeader {
    padding: 4.3em;
  }

  .c-FeatureHeader-text {
    width: 50%;
  }

  .c-FeatureHeader-figure {
    left: 55%;
    width: 499px;
  }
}
@include mq($from:lg) {
  .c-FeatureHeader-text {
    width: 35%;
  }

  .c-FeatureHeader-figure {
    left: 55%;
  }
}

@include mq($from:xl) {
  .c-FeatureHeader {
    margin: 2.986em 0;
  }
}
