.c-Offer-type {
  color: #fff;
  display: inline-block;
  font-size: .833rem;
  font-weight: $global-bold-weight;
  padding: .279rem .402rem;
  text-transform: uppercase;
}

.c-Offer--forSale {
  .c-Offer-type {
    background: $green;
    border-radius: $global-radius;
  }
}
