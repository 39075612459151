.c-Nav {
  padding-left: 4px;
  padding-right: 4px;
}

.c-Nav-action {
  color: #fff;
  display: block;
  font-size: 1em;
  font-weight: 400;
  padding: 8px 8px;
  text-decoration: none;
  text-transform: lowercase;
  white-space: nowrap;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

@include mq($from: sm) {
  .c-Nav-action {
    font-size: 1.125rem;
  }
}
