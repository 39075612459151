.o-AutoFill {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.o-AutoFill--sm {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.o-AutoFill--withGutter {
  grid-gap: $global-spacing-unit;
}
