/* ==========================================================================
   #GRID
   ========================================================================== */

/**
 * Core grid component
 *
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */

/* Grid container
   ========================================================================== */

/**
 * All content must be contained within child `Grid__cell` elements.
 *
 * 1. Account for browser defaults of elements that might be the root node of
 *    the component.
 */

.o-grid {
  display: flex; /* 1 */
  flex-flow: row wrap;
  margin: 0; /* 1 */
  padding: 0; /* 1 */
}

/**
 * No explicit width by default. Rely on combining a cell with a dimension
 * utility or a component class that extends 'Grid'.
 *
 * 1. Fix issue where elements with overflow extend past the
 *    `.o-grid > *` container - https://git.io/vw5oF
 * 2. Set flex items to full width by default
 */

.o-grid > * {
  box-sizing: inherit;
  min-width: 0; /* 1 */
  width: 100%; /* 2 */
}

/**
 * Modifier: center align all grid cells
 */

.o-grid--align-center {
  justify-content: center;
}

/**
 * Modifier: right align all grid cells
 */

.o-grid--align-right {
  justify-content: flex-end;
}

/**
 * Modifier: middle-align grid cells
 */

.o-grid--align-middle {
  align-items: center;
}

/**
 * Modifier: bottom-align grid cells
 */

.o-grid--align-bottom {
  align-items: flex-end;
}

/**
 * Modifier: allow cells to equal distribute width
 *
 * 1. Provide all values to avoid IE10 bug with shorthand flex
 *    http://git.io/vllC7
 *
 *    Use `0%` to avoid bug in IE10/11 with unitless flex basis
 *    http://git.io/vllWx
 */

.o-grid--fit > * {
  flex: 1 1 0%; /* 1 */
}

/**
 * Modifier: all cells match height of tallest cell in a row
 */

.o-grid--equal-height > * {
  display: flex;
}

/**
 * Modifier: gutters
 */

.o-grid--with-gutter {
  margin: 0 $global-spacing-unit * -1;
}

.o-grid--with-gutter > * {
  padding: 0 $global-spacing-unit;
}

.o-grid--condensed.o-grid--with-gutter {
  > * {
    padding: 0 $global-spacing-unit-sm;
  }
}

/**
 * Modifier: collection
 */
.o-grid--collection {
  margin: 0 -1px;
}

.o-grid--collection > * {
  padding: 1px;
}

/**
 * Modifier: no wrapping of rows
 */
.o-grid--nowrap {
  flex-wrap: nowrap;
}

@supports (display: grid) {
  .o-grid--auto-fill {
    display: grid;
    grid-gap: 2.096em;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    margin: 0;

    > * {
      padding: 0;
    }
  }
}
