/* =============================================================================
   #SCHEDULE
   ========================================================================== */

/**
 * Schedule table
 *
 * Must be applied to a table element
 *
 * <table class="c-Schedule">
 *   <tr>
 *     <td class="c-Schdule-event"></td>
 *     <td class="c-Schedule-dateloc"></td>
 *   </tr>
 * </table>
 */

 .c-Schedule-dateloc {
   font-size: .833em;
   text-align: right;
 }
