.c-table {
  thead {
    th {
      background: #fff;
      font-size: .875rem;
      position: sticky;
      text-transform: uppercase;
      top: 0;
    }
  }
}

.c-table--striped {
  tbody {
    tr:nth-child(even) {
      td {
        background: $grey-200;
      }
    }
    th,
    td {
      padding-bottom: .5em;
      padding-top: .5em;
    }
  }
}

.c-table--lined {
  tbody {
    th,
    td {
      border-bottom: 1px solid $grey-300;
      padding-bottom: .5em;
      padding-top: .5em;
    }
  }
}
